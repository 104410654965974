import { useEffect, useState } from 'react'
import Classnames from 'classnames'
import { useAtom } from 'jotai'
import produce from 'immer'

// helper functions
import { returnValue, returnBlockValue, returnUserContent } from '../../utils/storeHelperFunctions'

import { RiAddFill } from 'react-icons/ri'

// store
import {
  isBuilder,
  manifestDesign,
  pageContentDesign,
  pageContentBlocks,
  manifestBlocks,
  userContents,
} from '../../store'

import { guidGenerator } from '../../../index'

import SingleColumn from './ColumnLayouts/SingleColumn'
import TwoColumn from './ColumnLayouts/TwoColumn'
import ThreeColumn from './ColumnLayouts/ThreeColumn'

const GridBlock = ({ id, block, design, content, noStyle }) => {
  // manifest settings
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  const [builder] = useAtom(isBuilder)

  // custom page settings
  const [pageDesign] = useAtom(pageContentDesign)
  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)

  // data for blocks
  const [layoutBlocks, setLayoutBlocks] = useState([])

  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  useEffect(() => {
    setLayoutBlocks(returnBlockValue('blocks', 'content', opBlocks[block], content))
  }, [content])

  function addLayoutBlock(type) {
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.id == id
    })

    let newColumnLayout = {
      id: guidGenerator(),
      type: type,
    }

    if (type == '1-column') {
      newColumnLayout = {
        ...newColumnLayout,
        column_main: [],
      }
    } else if (type == '2-column') {
      newColumnLayout = {
        ...newColumnLayout,
        column_left: [],
        column_right: [],
      }
    } else if (type == '3-column') {
      newColumnLayout = {
        ...newColumnLayout,
        column_left: [],
        column_center: [],
        column_right: [],
      }
    }

    const nextState = produce(getPageBlocks, draft => {
      if (draft[getIndex].content.blocks) {
        draft[getIndex].content.blocks = [...draft[getIndex].content.blocks, newColumnLayout]
      } else {
        draft[getIndex].content.blocks = [newColumnLayout]
      }
    })
    setPageBlocks(nextState)
  }

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50 hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-gray-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
    >
      {/* {layoutBlocks.length == 0 && <div className="p-6 text-center text-gray-400">No Layout blocks...</div>} */}

      <div className="grid grid-cols-1 gap-2">
        {layoutBlocks.length !== 0 &&
          layoutBlocks.map((block, index) => {
            if (block.type == '1-column') {
              return <SingleColumn key={index} id={id} data={block} index={index} />
            } else if (block.type == '2-column') {
              return <TwoColumn key={index} id={id} data={block} index={index} />
            } else if (block.type == '3-column') {
              return <ThreeColumn key={index} id={id} data={block} index={index} />
            }
          })}
      </div>

      {builder && (
        <div className="flex justify-center text-sm ">
          <div
            className={Classnames(' flex items-center rounded border border-gray-300', {
              'mt-6': layoutBlocks.length > 0,
            })}
          >
            <button
              className="rounded-l bg-gray-50 hover:bg-gray-100 py-2 px-3 flex items-center space-x-1.5"
              onClick={() => addLayoutBlock('1-column')}
            >
              <span className="text-gray-500">
                <RiAddFill />
              </span>
              <span className="font-bold">1 Column Box</span>
            </button>
            <button
              className="bg-gray-50 hover:bg-gray-100 border-x border-gray-300 py-2 px-3 flex items-center space-x-1.5"
              onClick={() => addLayoutBlock('2-column')}
            >
              <span className="text-gray-500">
                <RiAddFill />
              </span>
              <span className="font-bold">2 Column Box</span>
            </button>
            <button
              className="rounded-r hover:bg-gray-100 bg-gray-50 py-2 px-3 flex items-center space-x-1.5"
              onClick={() => addLayoutBlock('3-column')}
            >
              <span className="text-gray-500">
                <RiAddFill />
              </span>
              <span className="font-bold">3 Column Box</span>
            </button>
          </div>
        </div>
      )}

      {/* <pre className="text-xs">{JSON.stringify(layoutBlocks, null, 2)}</pre> */}
    </div>
  )
}

export default GridBlock
