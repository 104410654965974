// store
import { useAtom } from 'jotai'
import { isBuilder, screen, screenOpen, staticFocusKey } from '../../store'

import { RiEditBoxLine } from 'react-icons/ri'

const StaticContentWrapper = ({ contentKey, children }) => {
  const [builder, toggleBuilder] = useAtom(isBuilder)
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getStaticFocusKey, setStaticFocusKey] = useAtom(staticFocusKey)

  if (builder) {
    return (
      <div
        onClick={e => {
          setStaticFocusKey(contentKey)
          setScreen('static')
          setScreenOpen(true)
          e.preventDefault()
        }}
        className="cursor-pointer relative group rounded hover:shadow-xl ring-2 ring-transparent hover:ring-brand-dark-gray "
      >
        <div className="group-hover:opacity-50">{children}</div>

        {/* overlay */}
        <div className="opacity-0 group-hover:opacity-100 flex items-center justify-center absolute top-0 left-0 h-full w-full bg-brand-dark-gray bg-opacity-25">
          <div className="text-lg font-bold bg-brand-dark-gray text-white rounded-full p-1.5 shadow">
            <RiEditBoxLine />
          </div>
        </div>
      </div>
    )
  } else {
    return <>{children}</>
  }
}

export default StaticContentWrapper
