import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import produce from 'immer'
import _ from 'lodash'

import {
  pageContentBlocks,
  isBuilder,
  gridBuilderMode,
  screen,
  screenOpen,
  blockEditID,
  blockEditType,
  gridLayout,
} from '../../_services/store'

import { RiEditBoxLine, RiFileCopyLine, RiDeleteBin6Line, RiDragMove2Fill } from 'react-icons/ri'

// sharedBlock components
import Header from '../../_services/components/sharedBlocks/Header'
import CardsBlock from '../../_services/components/sharedBlocks/CardsBlock'
import TodosBlock from '../../_services/components/sharedBlocks/TodosBlock'
import InputBlock from '../../_services/components/sharedBlocks/InputBlock'
import ButtonBlock from '../../_services/components/sharedBlocks/ButtonBlock'
import LinkBlock from '../../_services/components/sharedBlocks/LinkBlock'
import MultiChoiceBlock from '../../_services/components/sharedBlocks/MultiChoiceBlock'

const Components = {
  header: Header,
  inputblock: InputBlock,
  cardblock: CardsBlock,
  todosblock: TodosBlock,
  ctablock: ButtonBlock,
  multichoiceblock: MultiChoiceBlock,
  linkblock: LinkBlock,
}

const RenderBlock = ({ box }) => {
  const [getBlock, setBlock] = useState({})

  const [builder] = useAtom(isBuilder)
  const [getGridBuilderMode, setGridBuilderMode] = useAtom(gridBuilderMode)

  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)

  const [getGridLayout, setGridLayout] = useAtom(gridLayout)

  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getBlockEditID, setBlockEditID] = useAtom(blockEditID)
  const [getBlockEditType, setBlockEditType] = useAtom(blockEditType)

  useEffect(() => {
    const block = getPageBlocks.filter(b => b.boxLayoutId == box.i)

    if (block[0]) {
      setBlock(block[0])
    }
  }, [box, getPageBlocks])

  function deleteBlock() {
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.boxLayoutId == box.i
    })

    // delete block
    const nextState = produce(getPageBlocks, draft => {
      draft.splice(getIndex, 1)
    })
    setPageBlocks(nextState)

    const getLayoutIndex = _.findIndex(getGridLayout, function (o) {
      return o.i == getPageBlocks[getIndex].boxLayoutId
    })

    const nextStateLayout = produce(getGridLayout, draft => {
      draft.splice(getLayoutIndex, 1)
    })
    setGridLayout(nextStateLayout)
  }

  return (
    <>
      {builder && getGridBuilderMode == 'layout' && (
        <div className="absolute font-medium text-sm top-2 right-2 opacity-0 group-hover:opacity-100 shadow z-10 flex items-center space-x-2 bg-black text-white rounded py-1 px-1.5">
          <div
            onClick={() => {
              setBlockEditID(getBlock.id)
              setBlockEditType(getBlock.block)
              setScreen('editblock')
              setScreenOpen(true)
            }}
            className="bg-gray-200 flex items-center space-x-2 bg-opacity-10 hover:bg-opacity-20 text-white p-1.5 rounded cursor-pointer"
          >
            <RiEditBoxLine />
            <div className="">Edit</div>
          </div>

          {/* <div
            onClick={() => {
              cloneBlock()
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20 text-white p-1.5 rounded cursor-pointer"
          >
            <RiFileCopyLine />
          </div> */}

          <div
            onClick={() => {
              var r = confirm('Delete block - are you sure?')
              if (r == true) {
                deleteBlock()
              }
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20 text-white p-1.5 rounded cursor-pointer"
          >
            <RiDeleteBin6Line />
          </div>

          <div className="dragHandle cursor-move flex items-center space-x-2 bg-gray-200 bg-opacity-10 hover:bg-opacity-20 text-white p-1.5 rounded">
            <RiDragMove2Fill />
            <div className="">Move</div>
          </div>
        </div>
      )}
      {Components[getBlock.block] &&
        React.createElement(Components[getBlock.block], {
          id: getBlock.id,
          block: getBlock.block,
          content: getBlock.content,
          design: getBlock.design,
          index: box.i,
          noStyle: true,
        })}
    </>
  )
}

export default RenderBlock
