import { useState } from 'react'
import { useAtom } from 'jotai'
import { useFirstMountState, useDebounce, useEffectOnce } from 'react-use'

import { request, gql } from 'graphql-request'
import Cookies from 'js-cookie'

// store
import { userContents, isSharedContent } from '../../store'

const AutoSaver = () => {
  const [getUserContents, setUserContents] = useAtom(userContents)
  const [getIsSharedContent, setIsSharedContent] = useAtom(isSharedContent)

  const isFirstMount = useFirstMountState()
  const [autoSaveStart, setAutoSaveStart] = useState(false)

  // check if logged in - get user content - shared content
  useEffectOnce(async () => {
    const qs = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    // ## Check for shareId - get content
    if (qs.shareId) {
      // get content by shareId
      const getSharedContentQuery = gql`
        query getContents($id: String!) {
          getUserContent(id: $id) {
            id
            content
          }
        }
      `

      try {
        const sharedContent = await request('https://onepager.graphcdn.app', getSharedContentQuery, {
          id: qs.shareId,
        })

        setUserContents(JSON.parse(sharedContent.getUserContent.content))
        setIsSharedContent(true)
      } catch (err) {
        // check if cookie
        if (Cookies.get('user-content-id')) {
          await getUserContentsDB()
        }
      }
    } else {
      // check if has user content cookie
      if (Cookies.get('user-content-id')) {
        await getUserContentsDB()
      }
    }
  })

  async function getUserContentsDB() {
    const getUserContentsQuery = gql`
      query getContents($id: String!) {
        getUserContent(id: $id) {
          id
          content
        }
      }
    `

    const getContent = await request('https://onepager.graphcdn.app', getUserContentsQuery, {
      id: Cookies.get('user-content-id'),
    })

    setUserContents(JSON.parse(getContent.getUserContent.content))
  }

  // debouce save
  const [, debounceAutoSave] = useDebounce(
    async () => {
      if (!isFirstMount) {
        // post to server if LoggedIn
        if (getIsSharedContent) {
          // console.log('do not save')
        } else if (Cookies.get('user-content-id')) {
          setAutoSaveStart(true)

          // prevent double save

          console.log('auto save w/ user!')

          const saveUserContents = gql`
            mutation saveContents($id: String!, $content: String!) {
              updateUserContent(id: $id, content: $content) {
                id
              }
            }
          `

          await request('https://onepager.graphcdn.app', saveUserContents, {
            id: Cookies.get('user-content-id'),
            content: JSON.stringify(getUserContents),
          }).then(() => {
            setTimeout(function () {
              setAutoSaveStart(false)
            }, 500)
          })
        } else {
          // set local storage
          // setLocalUserContents(getUserContents)
          // setTimeout(function () {
          //   setAutoSaveStart(false)
          // }, 500)
        }
      }
    },
    2000,
    [getUserContents]
  )

  if (autoSaveStart) {
    return (
      <div className="fixed bottom-6 right-6 p-3 shadow-lg rounded bg-gray-600 text-white font-bold text-2xl z-50">
        Saving...
      </div>
    )
  } else {
    return null
  }
}

export default AutoSaver
