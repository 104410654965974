import Classnames from 'classnames'
import { useState, useEffect } from 'react'
import { RiAddFill } from 'react-icons/ri'

import { useAtom } from 'jotai'
import {
  screen,
  screenOpen,
  addBlockIndex,
  gridBlockAddIndex,
  newBoxLayout,
  gridColumn,
} from '../../../_services/store'

import * as GridTemplateParser from 'grid-template-parser'

import Selecto from 'react-selecto'

const Underlay = ({ addNewBlock }) => {
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getAddBlockIndex, setAddBlockIndex] = useAtom(addBlockIndex)
  const [getGridBlockAddIndex, setGridBlockAddIndex] = useAtom(gridBlockAddIndex)
  const [getNBewBoxLayout, setNewBoxLayout] = useAtom(newBoxLayout)

  const [gridRows, setGridRows] = useState(24)
  const [gridColumns, setGridColumns] = useAtom(gridColumn)
  const [gridTotalBoxes, setGridTotalBoxes] = useState(72)

  const [areas, setAreas] = useState(null)
  const [getGridParsed, setGridParsed] = useState(null)
  const [mezr, setMezr] = useState(null)

  let _GridTemplateParser = GridTemplateParser
  let template = _GridTemplateParser.template
  let area = _GridTemplateParser.area
  let gridParser = _GridTemplateParser.grid

  useEffect(async () => {
    setGridTotalBoxes(gridColumns * gridRows)
    setGrid()
    // const mezrInit = (await import('mezr')).default
    // setMezr(mezrInit)
  }, [])

  // set grid JS representation
  function setGrid() {
    let gridLayout = {}
    let columnsArray = setColumnsArray(gridRows, gridColumns)
    let globalRow = 0

    // set base grid
    for (var i = 0; i < gridTotalBoxes; i++) {
      // vars
      let setIndex = i + 1
      let column = i

      const columndEndCheck = columnsArray.filter(x => x == setIndex - 1)
      if (columndEndCheck[0]) {
        globalRow = globalRow + 1
      }

      if (globalRow > 0) {
        column = column - columnsArray[globalRow - 1]
      }

      gridLayout[setIndex] = area({
        x: column,
        y: globalRow,
        width: 1,
        height: 1,
      })
    }

    const areasCompute = template({
      width: gridColumns,
      height: gridRows,
      areas: gridLayout,
    })

    setAreas(areasCompute)
    setGridParsed(gridParser(areasCompute))
  }

  function setColumnsArray(row, column) {
    let array = []
    for (var i = 0; i < row; i++) {
      if (i > 0) {
        let num = i * parseInt(column)
        array.push(num)
      }
    }
    return array
  }

  return (
    <div
      className={Classnames('boxes absolute top-0 left-0 w-full grid  overflow-hidden rounded-md', {
        'grid-cols-3': gridColumns == 3,
        'grid-cols-4': gridColumns == 4,
        'grid-cols-5': gridColumns == 5,
        'grid-cols-6': gridColumns == 6,
      })}
    >
      <Selecto
        container={document.body}
        dragContainer={'.boxes'}
        selectableTargets={['.selectTarget']}
        selectByClick={true}
        selectFromInside={true}
        continueSelect={false}
        toggleContinueSelect={'shift'}
        keyContainer={window}
        hitRate={0}
        onSelect={e => {
          // console.log(e)
          e.added.forEach(el => {
            el.classList.add('underlaySelected')
          })
          e.removed.forEach(el => {
            el.classList.remove('underlaySelected')
          })
        }}
        onSelectEnd={async e => {
          // don't do anything unless there is a selection
          if (e.selected.length == 0) return false

          // get all selectedboxes
          let selectedBoxesID = []
          const grid = _GridTemplateParser.grid
          const getGrid = grid(areas)

          for (const el of e.selected) {
            selectedBoxesID.push(el.getAttribute('data-op-block-id'))
          }

          // check for overlap ?

          // get first box position
          let firstBoxSelected = selectedBoxesID[0]
          let firstBoxSelected_position = getGrid.areas[firstBoxSelected]

          // get last box position
          let lastBoxSelected = selectedBoxesID[selectedBoxesID.length - 1]
          let lastBoxSelected_position = getGrid.areas[lastBoxSelected]

          setGridBlockAddIndex(1)

          const newBox = {
            x: firstBoxSelected_position?.column.start - 1,
            y: firstBoxSelected_position?.row.start - 1,
            w: lastBoxSelected_position?.column.end - firstBoxSelected_position?.column.start,
            h: lastBoxSelected_position?.row.end - firstBoxSelected_position?.row.start,
            minW: 1,
            // maxW: 3,
          }

          setNewBoxLayout(newBox)

          setScreen('addblock')
          setScreenOpen(true)

          e.selected.forEach(el => {
            el.classList.remove('underlaySelected')
          })
        }}
      />

      {[...Array(gridRows * gridColumns)].map((e, i) => (
        <div
          className="flex selectTarget items-center text-5xl text-gray-300 cursor-pointer hover:text-blue-500 hover:bg-blue-50 hover:bg-opacity-25 justify-center border border-dashed border-gray-200"
          // className={dynamicEmptyBoxClasses(i)}
          data-op-block-id={i + 1}
          style={{
            minHeight: '250px',
            // backgroundColor: getDesign.section_bg_color
            //   ? getDesign.section_bg_color
            //   : 'auto',
          }}
          key={i}
          // onClick={() => {
          //   setGridBlockAddIndex(i + 1)
          //   setAddBlockIndex(null)
          //   setScreen('addblock')
          //   setScreenOpen(true)

          //   // addNewBlock(i + 1)
          //   // console.log('add box!', i + 1)
          // }}
        >
          <RiAddFill />
        </div>
      ))}
    </div>
  )
}

export default Underlay
