import { useAtom } from 'jotai'
import { isBuilder, pageContentBlocks } from '../../store'

// shared
import BlockRenderer from '../shared/BlockRenderer'
import DragWrapper from '../shared/DragWrapper'
import AddBlockBuilder from './AddBlockBuilder'

// sharedBlock components
import Header from './Header'
import CardsBlock from './CardsBlock'
import TodosBlock from './TodosBlock'
import InputBlock from './InputBlock'
import ButtonBlock from './ButtonBlock'
import LinkBlock from './LinkBlock'
import MultiChoiceBlock from './MultiChoiceBlock'
import GridBlock from './GridBlock'
import GridBlockClassic from './GridBlockClassic'

const Components = {
  header: Header,
  inputblock: InputBlock,
  cardblock: CardsBlock,
  todosblock: TodosBlock,
  ctablock: ButtonBlock,
  multichoiceblock: MultiChoiceBlock,
  linkblock: LinkBlock,
  grid: GridBlock,
  gridClassic: GridBlockClassic,
}

const SharedBlocks = () => {
  const [builder] = useAtom(isBuilder)
  const [pageBlocks] = useAtom(pageContentBlocks)

  return (
    <>
      <DragWrapper>
        {pageBlocks.map((block, index) => (
          <BlockRenderer key={index} index={index} Components={Components} block={block} />
        ))}
      </DragWrapper>

      {/* add block button builder */}
      {builder && <AddBlockBuilder btnText="Add Block" />}
    </>
  )
}

export default SharedBlocks
