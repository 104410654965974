import Cookies from 'js-cookie'
import { Provider } from 'jotai'

import {
  isBuilder,
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  isLoggedIn,
  userEmail,
  userContents,
  userContentsId,
  isEmbed,
  gridLayout,
  gridLayoutCSS,
  title,
  subdomain,
} from '../store'

const JotaiStoreWrapper = ({ manifest, content, getTitle, getSubdomain, is_builder, is_embed, children }) => {
  function getUserContents() {
    let userContentsSource = {}

    // create blank user contents
    content.blocks.forEach(block => {
      if (manifest.blocks[block.block].settings.type == 'editable') {
        // merge source with page contents
        userContentsSource[block.id] = {
          ...block.usercontent,
          ...manifest.blocks[block.block].usercontent,
        }
      }
    })

    return userContentsSource
  }

  return (
    <Provider
      initialValues={[
        [isLoggedIn, Cookies.get('user-content-email') ? true : false],
        [userEmail, Cookies.get('user-content-email')],
        [userContents, getUserContents()],
        [userContentsId, Cookies.get('user-content-id')],
        // [isSharedContent, router.query.shareId ? true : false],
        [isBuilder, is_builder],
        [isEmbed, is_embed],
        [manifestStatic, manifest.static],
        [manifestDesign, manifest.design],
        [manifestBlocks, manifest.blocks],
        [pageContentStatic, content.static],
        [pageContentDesign, content.design],
        [pageContentBlocks, content.blocks],
        [gridLayout, content.gridLayout],
        [gridLayoutCSS, content.gridLayoutCSS],
        [title, getTitle],
        [subdomain, getSubdomain],
      ]}
    >
      {children}
    </Provider>
  )
}

export default JotaiStoreWrapper
