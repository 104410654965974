import { useAtom } from 'jotai'
import produce from 'immer'
import { nanoid } from 'nanoid'

import { userContents, todosFocusId, cardsEditId, cardsBlockEditId, cardsShowModal } from '../../../store'

const CardAdd = ({ contentKey, blockId, className, style, text, newText }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)
  // todo focus
  const [getTodoFocusId, setTodoFocusId] = useAtom(todosFocusId)

  const [getCardEditId, setCardEditId] = useAtom(cardsEditId)
  const [getCardBlockEditId, setCardBlockEditId] = useAtom(cardsBlockEditId)
  const [showCardModal, toggleCardModal] = useAtom(cardsShowModal)

  function addNewTodo() {
    const newTodoId = 'card-' + nanoid()

    const nextState = produce(getUserContents, draft => {
      if (draft[blockId]) {
        draft[blockId][contentKey].push({
          id: newTodoId,
          text: newText ? newText : '',
          notes: '',
        })
      } else {
        draft[blockId] = {
          [contentKey]: [
            {
              id: newTodoId,
              text: newText ? newText : '',
              notes: '',
            },
          ],
        }
      }
    })

    setUserContents(nextState)
    setTodoFocusId(newTodoId)
    setCardEditId(newTodoId)

    setCardBlockEditId(blockId)

    toggleCardModal(true)
  }

  return (
    <div onClick={addNewTodo} className={className} style={style}>
      {text}
    </div>
  )
}

export default CardAdd
