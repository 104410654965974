import { useAtom } from 'jotai'
import produce from 'immer'
import { userContents } from '../../../store'

const TodoDelete = ({ blockId, contentKey, index, className, children }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  function deleteTask() {
    const nextState = produce(getUserContents, draft => {
      draft[blockId][contentKey].splice(index, 1)
    })
    setUserContents(nextState)
  }

  return (
    <div onClick={deleteTask} className={className}>
      {children}
    </div>
  )
}

export default TodoDelete
