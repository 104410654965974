import Classnames from 'classnames'
import ReactPlayer from 'react-player'

const Video = ({ url, classNames }) => {
  return (
    <div
      className={Classnames(
        'player-wrapper rounded shadow-lg overflow-hidden',
        classNames
      )}
      style={{
        position: 'relative',
        paddingTop: '56.25%',
      }}
    >
      <ReactPlayer
        controls={false}
        playing={false}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        url={url}
      />
    </div>
  )
}

export default Video
