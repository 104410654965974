import Classnames from 'classnames'
import { useAtom } from 'jotai'
import {
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  userContents,
  isBuilder,
  isLoggedIn,
  openSignUpModal,
  openShareModal,
  isEmbed,
} from '../../_services/store'

import { RiFileCopy2Line, RiSave2Line } from 'react-icons/ri'

import { returnValue } from '../../_services/utils/storeHelperFunctions'

// shared components
import StaticContentWrapper from '../../_services/components/shared/StaticContentWrapper'
import Video from '../../_services/components/shared/Video'
import OnepagerFooter from '../../_services/components/shared/OnepagerFooter'

// sharedBlock renderer
import BlockRenderer from '../../_services/components/sharedBlocks/BlockRenderer'

const Header = () => {
  const [builder] = useAtom(isBuilder)
  const [embed] = useAtom(isEmbed)

  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  const [getLoggedIn, setLoggedIn] = useAtom(isLoggedIn)
  const [isSignUpModalOpen, toggleSignUpModal] = useAtom(openSignUpModal)
  const [isShareModalOpen, toggleShareModal] = useAtom(openShareModal)

  return (
    <div
      className={Classnames('border-b-2 border-black border-opacity-25 relative z-10', {
        'px-12': embed,
      })}
      style={{
        backgroundColor: returnValue('bgcolor', opDesign, pageDesign),
        color: returnValue('maintextcolor', opDesign, pageDesign),
      }}
    >
      {/* background image */}
      {returnValue('bgimgurl', opDesign, pageDesign) && (
        <div
          className="absolute top-0 left-0 z-0 h-full w-full  bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${returnValue('bgimgurl', opDesign, pageDesign)})`,
            opacity: returnValue('bgimgopacity', opDesign, pageDesign),
          }}
        ></div>
      )}

      <div className="max-w-6xl mx-auto p-8 md:p-0">
        <div className="md:flex items-center relative z-10 pt-3 md:pt-12 pb-16">
          {/* headline / subheadline */}
          <div
            className={Classnames({
              'md:w-3/4 md:pr-12':
                !returnValue('videourl', opStatic, pageStatic) &&
                !returnValue('imgurl', opStatic, pageStatic),
              'md:w-1/2 md:pr-12':
                returnValue('videourl', opStatic, pageStatic) || returnValue('imgurl', opStatic, pageStatic),
            })}
          >
            <h1
              className={Classnames('text-center md:text-left font-bold text-5xl md:text-6xl', {
                appTitleItem: returnValue('herofont', opDesign, pageDesign) == 'sans',
              })}
              style={{
                color: returnValue('headlinetextcolor', opDesign, pageDesign),
              }}
            >
              <StaticContentWrapper contentKey="headline">
                {returnValue('headline', opStatic, pageStatic)}
              </StaticContentWrapper>
            </h1>
            <div className="mt-4 mb-6 text-center md:text-left  text-xl font-medium">
              <StaticContentWrapper contentKey="subheadline">
                {returnValue('subheadline', opStatic, pageStatic)}
              </StaticContentWrapper>
            </div>

            {!embed && (
              <>
                {!getLoggedIn && (
                  <StaticContentWrapper contentKey="savebutton">
                    <div
                      className="py-2 px-4 mb-5 inline-flex items-center shadow-md ring-2 ring-black ring-opacity-40     rounded text-lg  font-bold text-white cursor-pointer"
                      style={{
                        backgroundColor: returnValue('savebutton', opDesign, pageDesign),
                      }}
                      onClick={() => {
                        if (!builder) {
                          toggleSignUpModal(true)
                        }
                      }}
                    >
                      <div>{returnValue('savebutton', opStatic, pageStatic)}</div>
                      <div className="ml-3">
                        <RiSave2Line />
                      </div>
                    </div>
                  </StaticContentWrapper>
                )}

                {/* share button */}
                {getLoggedIn && (
                  <div
                    onClick={() => {
                      toggleShareModal(true)
                    }}
                    className="py-2 px-4 mb-5 inline-flex items-center shadow-md ring-2 ring-black ring-opacity-40   hover:shadow-xl focus-within:shadow-xl focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-blue-100 rounded text-lg  font-bold text-white cursor-pointer"
                    style={{
                      backgroundColor: returnValue('savebutton', opDesign, pageDesign),
                    }}
                  >
                    <div>Share</div>
                    <div className="ml-3">
                      <RiFileCopy2Line />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div
            className={Classnames({
              'md:w-1/2':
                returnValue('videourl', opStatic, pageStatic) || returnValue('imgurl', opStatic, pageStatic),
            })}
          >
            {/* video */}
            {returnValue('videourl', opStatic, pageStatic) && (
              <Video url={returnValue('videourl', opStatic, pageStatic)} classNames="mb-8" />
            )}

            {/* img */}
            {returnValue('imgurl', opStatic, pageStatic) && (
              <img
                src={returnValue('imgurl', opStatic, pageStatic)}
                alt=""
                className="rounded shadow-lg mb-8"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
