import Classnames from 'classnames'
import Header from './components/Header'
import GridBuilder from './components/Builder'

import Grid from './components/Grid'

import { useAtom } from 'jotai'
import {
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  userContents,
  isBuilder,
  isLoggedIn,
  openSignUpModal,
  openShareModal,
  isEmbed,
} from '../_services/store'

const Render = ({ id }) => {
  const [builder] = useAtom(isBuilder)
  const [embed] = useAtom(isEmbed)

  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  return (
    <div className="bg-gray-200 min-h-screen">
      <Header />

      <div className="">{builder && <GridBuilder />}</div>

      {!builder && (
        <>
          <div
            className={Classnames('relative z-10 -mt-16 overflow-hidden  bg-white rounded-md', {
              'mx-12': embed,
              'max-w-6xl mx-auto': !embed,
            })}
          >
            <Grid />
          </div>
          <div className="pb-16"></div>
        </>
      )}
    </div>
  )
}

export default Render
