import Script from 'next/script'

const Tracking = ({ id, uid, collectionId }) => {
  return (
    <>
      <Script
        defer
        src="https://plausible.io/js/plausible.manual.js"
        data-domain="app.onepager.io"
        onLoad={() => {
          window.plausible =
            window.plausible ||
            function () {
              ;(window.plausible.q = window.plausible.q || []).push(arguments)
            }

          function prepareUrl(id) {
            const url = new URL(location.href)
            // let customUrl = url.protocol + '//' + url.hostname + '/t/' + uid + '/' + collectionId
            let customUrl = url.protocol + '//' + url.hostname + '/t/' + uid

            return customUrl + '/' + id
          }
          plausible('pageview', { u: prepareUrl(id) })
        }}
      />
    </>
  )
}

export default Tracking
