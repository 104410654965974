import BlockWrapper from '../_services/components/shared/BlockWrapper'
import Render from './Render'

import manifest from '../_services/manifests/CardScroller'

const RenderOnepager = props => {
  return (
    <BlockWrapper {...props} manifest={manifest}>
      <Render {...props} />
    </BlockWrapper>
  )
}

export default RenderOnepager
