export { default as Grid } from './Grid/index'

// manifests
export { default as Manifests } from './_services/manifests/index'

export { default as JotaiStoreWrapper } from './_services/components/JotaiStoreWrapper'

// store
export {
  isBuilder,
  userContents,
  headerSize,
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  screen,
  screenOpen,
  staticFocusKey,
  blockEditID,
  blockEditType,
  addBlockIndex,
  isGrid,
  gridLayout,
  gridLayoutCSS,
  gridColumn,
  gridBlockAddIndex,
  gridBuilderMode,
  newBoxLayout,
  title,
  subdomain,
  inGridBlock,
  gridBlockId,
  gridColumnId,
  gridColumnKey,
  addBlockId,
} from './_services/store/index'

// block onepagers
export { default as CenterScroller } from './CenterScroller/index'
export { default as CardScroller } from './CardScroller/index'
export { default as SideScroller } from './SideScroller/index'

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}
