import Head from 'next/head'

import { returnValue } from '../../utils/storeHelperFunctions'

import { useAtom } from 'jotai'
import { manifestStatic, pageContentStatic } from '../../store'

const SEO = ({ id, subdomain, share_image, custom_css }) => {
  const [opStatic] = useAtom(manifestStatic)
  const [pageStatic] = useAtom(pageContentStatic)

  return (
    <Head>
      <title>{returnValue('headline', opStatic, pageStatic)}</title>
      <meta name="title" content={returnValue('headline', opStatic, pageStatic)} />
      <meta name="description" content={returnValue('subheadline', opStatic, pageStatic)} />
      {/* mobile */}
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover, user-scalable=no" />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://${subdomain}.onepager.app/`} />
      <meta property="og:title" content={returnValue('headline', opStatic, pageStatic)} />
      <meta property="og:description" content={returnValue('subheadline', opStatic, pageStatic)} />
      {/* <!-- Twitter -- /> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://${subdomain}.onepager.app/`} />
      <meta property="twitter:title" content={returnValue('headline', opStatic, pageStatic)} />
      <meta property="twitter:description" content={returnValue('subheadline', opStatic, pageStatic)} />
      {/* IMAGES */}

      {/* check if has share_image */}
      {share_image && (
        <>
          <meta property="og:image" content={share_image} />
          <meta property="twitter:image" content={share_image} />
        </>
      )}

      {/* no custom share image - use microlink / cloudinary */}
      {!share_image && (
        <>
          <meta
            property="og:image"
            content={`https://res.cloudinary.com/dfz1ka7q3/image/upload/w_1200/${id}.webp`}
          />
          <meta
            property="twitter:image"
            content={`https://res.cloudinary.com/dfz1ka7q3/image/upload/w_1200/${id}.webp`}
          />
        </>
      )}

      {custom_css && <style>{custom_css}</style>}
    </Head>
  )
}

export default SEO
