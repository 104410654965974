import Blocks from './Blocks'

export default {
  id: '312392809556476481',
  type: 'editable',

  static: {
    brandname: {
      label: 'Brand Name',
      desc: 'shows in the top right corner',
      control: 'input',
      value: 'Your Company Name',
    },
    headline: {
      label: 'Main Headline',
      desc: '',
      control: 'input',
      value: 'Main Headline ',
    },
    subheadline: {
      label: 'Sub Headline',
      desc: '',
      control: 'textarea',
      value: 'Sub Headline text...',
    },
    covertitle: {
      label: 'Cover settings',
      desc: '',
      control: 'title',
      value: 'blue',
    },
    videourl: {
      label: 'Video',
      desc: 'optional video (youtube / vimeo / mp4 )',
      control: 'input',
      value: '',
    },
    imgurl: {
      label: 'Image',
      desc: 'optional cover image',
      control: 'upload',
      value: '',
    },
    blockheadline: {
      label: 'Content Headline',
      desc: 'small headline',
      control: 'input',
      value: 'Edit this content...',
    },
    savebutton: {
      label: 'Save Button Text',
      desc: 'copy on the save button',
      control: 'input',
      value: 'Save Your Changes',
    },
  },

  design: {
    maintitle: {
      label: 'Hero Desgin Settings',
      desc: '',
      control: 'title',
      value: '',
    },
    bgcolor: {
      label: 'Hero Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#4c5664',
    },
    bgcolor_nav: {
      label: 'Hero Header Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#4b5563',
    },
    bgimgurl: {
      label: 'Hero Background Image',
      desc: 'optional',
      control: 'upload',
      value: '',
    },
    bgimgopacity: {
      label: 'BG Image Opacity',
      desc: 'optional',
      control: 'slider',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.1,
    },
    maintextcolor: {
      label: 'Hero Text Color',
      desc: 'text color for the left side',
      control: 'colorpicker',
      value: '#FFF',
    },
    herofont: {
      label: 'Hero Font',
      desc: '',
      control: 'select',
      value: 'default',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Sans', value: 'sans' },
      ],
    },
    headlinetextcolor: {
      label: 'Hero Headline Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    pagebg: {
      label: 'Main Page Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    savetitle: {
      label: 'Content Design Settings',
      desc: '',
      control: 'title',
      value: 'blue',
    },
    savebutton: {
      label: 'Save Button Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    blockheaderbgcolor: {
      label: 'Content Header BG Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    blockheadertextcolor: {
      label: 'Content Header Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    blockbgcolor: {
      label: 'Content BG Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    blocktextcolor: {
      label: 'Content Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    cardtitle: {
      label: 'Block Design Settings',
      desc: '',
      control: 'title',
      value: 'purple',
    },
    cardbgcolor: {
      label: 'Block Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    cardtextcolor: {
      label: 'Block Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    cardbordersize: {
      label: 'Block Border Size',
      desc: '',
      control: 'select',
      value: '1px',
      options: [
        { label: '1px', value: '1px' },
        { label: '2px', value: '2px' },
        { label: '3px', value: '3px' },
      ],
    },
    cardbordercolor: {
      label: 'Block Border Color',
      desc: '',
      control: 'colorpicker',
      value: '',
    },
    // focusborder: {
    //   label: 'Focus Border Color',
    //   desc: '',
    //   control: 'colorpicker',
    //   value: '#2563eb',
    // },
    generalcardstitle: {
      label: 'Card Block Type Design Settings',
      desc: '',
      control: 'title',
      value: 'blue',
    },
    theme_addbutton: {
      label: 'Common Add Button Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#e4e7eb',
    },
    theme_addbuttontext: {
      label: 'Common Add Button Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    theme_cardbg: {
      label: 'Card / Todo Background',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    theme_cardtext: {
      label: 'Card / Todo Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },
    theme_cardborder: {
      label: 'Card / Todo Border Color',
      desc: '',
      control: 'colorpicker',
      value: '#DDD',
    },
    theme_multichoicecolor: {
      label: 'Multi Choice Color',
      desc: '',
      control: 'colorpicker',
      value: '#2563eb',
    },
    theme_todobgcolor: {
      label: 'Todo Tick BG',
      desc: '',
      control: 'colorpicker',
      value: '#21252810',
      gridPlacement: '1/2',
    },
    theme_todobordercolor: {
      label: 'Todo Tick Border',
      desc: '',
      control: 'colorpicker',
      value: '#21252830',
      gridPlacement: '1/2',
    },
  },

  blocks: {
    ...Blocks,
  },
}

// export default TypeManifest
