import Classnames from 'classnames'
import { useAtom } from 'jotai'

// helper functions
import { returnValue, returnBlockValue, returnUserContent } from '../../utils/storeHelperFunctions'

// components
import TodosWrapper from '../headless/Todo/TodosWrapper'
import TodoItem from '../headless/Todo/TodoItem'
import TodoTick from '../headless/Todo/TodoTick'
import TodoDrag from '../headless/Todo/TodoDrag'
import TodoDelete from '../headless/Todo/TodoDelete'
import TodoAdd from '../headless/Todo/TodoAdd'
import TodoInput from '../headless/Todo/TodoInput'

// store
import { manifestDesign, pageContentDesign, manifestBlocks, userContents, todosFocusId } from '../../store'

import { RiDeleteBack2Line, RiArrowUpDownLine, RiCheckFill } from 'react-icons/ri'

const TodosBlock = ({ id, block, design, content, noStyle }) => {
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  const [pageDesign] = useAtom(pageContentDesign)

  // todo
  const [getTodoFocusId] = useAtom(todosFocusId)

  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50 hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-gray-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div className="text-xl font-bold">
        {returnBlockValue('title', 'content', opBlocks[block], content)}
      </div>

      <div className="text-lg mt-1 opacity-70">
        {returnBlockValue('desc', 'content', opBlocks[block], content)}
      </div>

      <div className="mt-4">
        <TodosWrapper contentKey="todos" blockId={id} className="space-y-1.5 cursor-default">
          {returnUserContent('todos', id, getUserContents)?.map((block, index) => (
            <TodoItem
              key={block.id}
              itemId={block.id}
              blockId={id}
              index={index}
              contentKey="todos"
              className={Classnames('flex items-center py-2 px-3 rounded', {
                'border border-gray-300 shadow-sm': !block.completed,
                'border border-blue-200 opacity-50': block.completed,
              })}
              style={{
                backgroundColor: returnValue('theme_cardbg', opDesign, pageDesign),
                color: returnValue('theme_cardtext', opDesign, pageDesign),
                borderColor: returnValue('theme_cardborder', opDesign, pageDesign),
              }}
              handle={
                <TodoDrag className="relative text-sm opacity-0 hover:bg-blue-600 hover:text-white group-hover:opacity-100 cursor-move -left-6  py-1 px-0.5 rounded ">
                  <RiArrowUpDownLine />
                </TodoDrag>
              }
              remove={
                <TodoDelete
                  blockId={id}
                  index={index}
                  contentKey="todos"
                  className="absolute opacity-0  hover:border-red-400 hover:text-white hover:bg-red-500 group-hover:opacity-50 cursor-pointer  right-2  py-1 px-1.5 rounded shadow-sm"
                >
                  <RiDeleteBack2Line />
                </TodoDelete>
              }
            >
              <TodoTick
                contentKey="todos"
                blockId={id}
                index={index}
                className="h-8 w-8 mr-3 flex-shrink-0 rounded cursor-pointer flex items-center  justify-center border  shadow-inner"
                style={{
                  backgroundColor: returnValue('theme_todobgcolor', opDesign, pageDesign),
                  color: returnValue('theme_cardtext', opDesign, pageDesign),
                  borderColor: returnValue('theme_todobordercolor', opDesign, pageDesign),
                }}
                completed={block.completed}
                completedBlock={
                  <div>
                    <RiCheckFill />
                  </div>
                }
                notCompletedBlock={<div></div>}
              />
              <TodoInput
                value={block.text}
                placeholder="..."
                contentKey="todos"
                blockId={id}
                index={index}
                className={Classnames(
                  'border-transparent outline-none focus:ring-transparent focus:border-transparent font-medium ml-0 mr-6',
                  {
                    'line-through': block.completed,
                  }
                )}
                addNewOnEnter={true}
                onEnter={() => {}}
                autoFocus={getTodoFocusId == block.id}
              />
            </TodoItem>
          ))}
        </TodosWrapper>

        <div className="mt-2">
          <TodoAdd
            blockId={id}
            contentKey="todos"
            text={returnBlockValue('addtodobutton', 'content', opBlocks[block], content)}
            style={
              {
                // backgroundColor: returnValue('theme_addbutton', opDesign, pageDesign),
                // color: returnValue('theme_addbuttontext', opDesign, pageDesign),
              }
            }
            className="inline-block ml-1 mt-2 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 ring-offset-gray-50 cursor-pointer bg-white text-gray-800 hover:bg-blue-500 hover:text-blue-50 hover:ring-offset-blue-300 hover:ring-blue-600 font-bold py-1.5 px-4 rounded"
          />
        </div>
      </div>
    </div>
  )
}

export default TodosBlock
