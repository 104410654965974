import { nanoid } from 'nanoid'
import { useAtom } from 'jotai'
import produce from 'immer'
import Classnames from 'classnames'

// store
import {
  screen,
  screenOpen,
  userContents,
  manifestBlocks,
  pageContentBlocks,
  blockEditID,
  blockEditType,
  addBlockIndex,
} from '../../store'

const AddBlockBuilder = ({ autoType, bgColor, btnText }) => {
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [manifest] = useAtom(manifestBlocks)
  const [getUserContents, setUserContents] = useAtom(userContents)
  const [getBlockEditID, setBlockEditID] = useAtom(blockEditID)
  const [getBlockEditType, setBlockEditType] = useAtom(blockEditType)
  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)
  const [getAddBlockIndex, setAddBlockIndex] = useAtom(addBlockIndex)

  function addNewBlock() {
    setAddBlockIndex(null)

    if (autoType) {
      const newBlockObj = {
        id: 'block-' + nanoid(),
        block: autoType,
        content: {},
        design: {},
      }

      const nextState = produce(getPageBlocks, draft => {
        draft.push(newBlockObj)
      })
      setPageBlocks(nextState)

      setBlockEditID(newBlockObj.id)
      setBlockEditType(autoType)
      setScreenOpen(true)
      setScreen('editblock')

      // add user contents blank - if editable block
      if (manifest[autoType].settings.type == 'editable') {
        const nextUserContentsState = produce(getUserContents, draft => {
          draft[newBlockObj.id] = manifest[autoType].usercontent
        })
        setUserContents(nextUserContentsState)
      }
    } else {
      setScreenOpen(true)
      setScreen('addblock')
    }
  }

  return (
    <div
      onClick={addNewBlock}
      className={Classnames(
        'bg-black hover:bg-blue-600 inline-block cursor-pointer hover:shadow-md py-2 px-4 rounded text-white font-bold'
      )}
    >
      {btnText}
    </div>
  )
}

export default AddBlockBuilder
