import React from 'react'

const LinkComponent = ({ data, sameWindow, className, children }) => {
  function goToLink() {
    if (sameWindow) {
      window.open(data.url, '_self').focus()
    } else {
      window.open(data.url, '_blank').focus()
    }
  }

  return (
    <div onClick={goToLink} className={className}>
      {React.cloneElement(children, {
        url: data?.url,
        meta: data?.meta,
      })}
    </div>
  )
}

export default LinkComponent
