import { atom } from 'jotai'

// misc
export const isLoggedIn = atom(false)
export const openSignUpModal = atom(false)
export const openShareModal = atom(false)
export const userEmail = atom('')

// user contents
export const userContents = atom({})
export const userContentsId = atom('')
// export const userContents = atomWithStorage('userContent', {})

export const isSharedContent = atom(false)

export const todosFocusId = atom('')

export const cardsShowModal = atom(false)
export const cardsEditId = atom('')
export const cardsBlockEditId = atom('')

export const collectionData = atom({})

export const headerSize = atom(0)

export const title = atom('')
export const subdomain = atom('')

// embed - membership / coach / lead magnet
export const isEmbed = atom(false)

// builder
export const isBuilder = atom(false)
export const isGrid = atom(false)
export const screen = atom('blocks')
export const screenOpen = atom(false)
export const blockEditID = atom('')
export const blockEditType = atom('')
export const staticFocusKey = atom('')
export const addBlockIndex = atom(null)
export const addBlockId = atom('')
export const inGridBlock = atom(false)
export const gridBlockId = atom('')
export const gridColumnId = atom('')
export const gridColumnKey = atom('')

// manifest content
export const manifestStatic = atom({})
export const manifestDesign = atom({})
export const manifestBlocks = atom([])

// page content
export const pageContentStatic = atom({})
export const pageContentDesign = atom({})
export const pageContentBlocks = atom([])

// grid builder
export const gridLayout = atom([])
export const gridLayoutCSS = atom([])
export const gridColumn = atom(6)
export const gridBlockAddIndex = atom(0)
export const gridBuilderMode = atom('layout')
export const newBoxLayout = atom({})
