import { useAtom } from 'jotai'
import { gridLayoutCSS, pageContentBlocks } from '../../_services/store'
import Classnames from 'classnames'

import RenderBlock from './RenderBlock'
import DragWrapper from '../../_services/components/shared/DragWrapper'

const GridComponent = () => {
  const [getGridLayoutCSS, setGridLayoutCSS] = useAtom(gridLayoutCSS)
  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)

  const checkTopEdge = box => box['grid-row-start'] == 1
  const checkLeftEdge = box => box['grid-column-start'] == 1

  const checkTopRightCorner = box => {
    return box['grid-row-start'] == 1 && box['grid-column-start'] + box['grid-column-end'] == 7
  }

  const checkTopLeftCorner = box => {
    return box['grid-row-start'] == 1 && box['grid-column-start'] == 1
  }

  const checkBottomLeftCorner = box => {
    return box['grid-row-end'] == 6 && box['grid-column-start'] == 1
  }

  const checkBottomRightCorner = box => {
    return (
      box['grid-row-end'] + box['grid-row-start'] == 7 &&
      box['grid-column-start'] + box['grid-column-end'] == 7
    )
  }

  // Dynamic classes
  function dynamicClasses(box) {
    return Classnames(`bg-white boxThing p-8 box flex flex-col w-full h-full relative`, {
      hasTopBorder: checkTopEdge(box),
      hasLeftBorder: checkLeftEdge(box),
      hasTopRightRadius: checkTopRightCorner(box),
      hasTopLeftRadius: checkTopLeftCorner(box),
      hasBottomLeftRadius: checkBottomLeftCorner(box),
      hasBottomRightRadius: checkBottomRightCorner(box),
    })
  }

  return (
    <div style={{ gap: '0px 0px', padding: '0px' }} className="md:grid grid-cols-6 overflow-hidden">
      {getGridLayoutCSS.map((box, index) => (
        <div
          key={index}
          style={{
            minHeight: box['grid-row-end'] * 250,
            gridRow: box['grid-row-start'] + '/ span ' + box['grid-row-end'],
            gridColumn: box['grid-column-start'] + '/ span ' + box['grid-column-end'],
          }}
          className={dynamicClasses(box)}
        >
          <DragWrapper>
            <RenderBlock box={box} />
          </DragWrapper>
        </div>
      ))}
    </div>
  )
}

export default GridComponent
