import { useAtom } from 'jotai'

// helper functions
import { returnValue } from '../../_services/utils/storeHelperFunctions'
import StaticContentWrapper from '../../_services/components/shared/StaticContentWrapper'

// store
import {
  isBuilder,
  isLoggedIn,
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  openSignUpModal,
  openShareModal,
} from '../../_services/store'

import { RiFileCopy2Line, RiSave2Line } from 'react-icons/ri'

const BlocksHeader = () => {
  const [builder] = useAtom(isBuilder)
  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  const [getLoggedIn, setLoggedIn] = useAtom(isLoggedIn)
  const [isSignUpModalOpen, toggleSignUpModal] = useAtom(openSignUpModal)
  const [isShareModalOpen, toggleShareModal] = useAtom(openShareModal)

  return (
    <div
      className="opr-blocks-header flex items-center justify-between px-8 py-6 sticky top-0 z-10 border-b border-black border-opacity-10"
      style={{
        backgroundColor: returnValue('blockheaderbgcolor', opDesign, pageDesign),
        color: returnValue('blockheadertextcolor', opDesign, pageDesign),
      }}
    >
      <div className="font-medium text-xl italic">
        <StaticContentWrapper contentKey="blockheadline">
          {returnValue('blockheadline', opStatic, pageStatic)}
        </StaticContentWrapper>
      </div>
      <div className="flex items-center space-x-2">
        {!getLoggedIn && (
          <StaticContentWrapper contentKey="savebutton">
            <div
              className="py-2 px-4 flex items-center border border-black border-opacity-20  rounded text-lg  font-bold text-white cursor-pointer"
              style={{
                backgroundColor: returnValue('savebutton', opDesign, pageDesign),
              }}
              onClick={() => {
                if (!builder) {
                  toggleSignUpModal(true)
                }
              }}
            >
              <div>{returnValue('savebutton', opStatic, pageStatic)}</div>
              <div className="ml-3">
                <RiSave2Line />
              </div>
            </div>
          </StaticContentWrapper>
        )}

        {/* share button */}
        {getLoggedIn && (
          <div
            onClick={() => {
              toggleShareModal(true)
            }}
            className="py-2 px-4 flex items-center border border-black border-opacity-20 rounded text-lg  font-bold text-white cursor-pointer"
            style={{
              backgroundColor: returnValue('savebutton', opDesign, pageDesign),
            }}
          >
            <div>Share</div>
            <div className="ml-3">
              <RiFileCopy2Line />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BlocksHeader
