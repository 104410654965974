import Classnames from 'classnames'
import { useAtom } from 'jotai'

// helper functions
import { returnBlockValue } from '../../utils/storeHelperFunctions'

// store
import { manifestBlocks } from '../../store'

const HeaderBlock = ({ id, block, design, content, index }) => {
  const [opBlocks] = useAtom(manifestBlocks)

  return (
    <div
      className={Classnames('px-5 md:px-0', {
        'pt-8': index !== 0,
      })}
    >
      <div
        className={Classnames('', {
          'border-l-4 pl-5 py-3':
            returnBlockValue('bordertoggle', 'design', opBlocks[block], design) == 'showborder',
        })}
        style={{
          borderColor: returnBlockValue('bordercolor', 'design', opBlocks[block], design),
        }}
      >
        <div
          className={Classnames('font-bold text-3xl', {
            // 'text-lg':
            //   returnBlockValue(
            //     'headlinesize',
            //     'design',
            //     opBlocks[block],
            //     design
            //   ) == 'default',
            // 'text-xl':
            //   returnBlockValue(
            //     'headlinesize',
            //     'design',
            //     opBlocks[block],
            //     design
            //   ) == 'xl',
            // 'text-2xl':
            //   returnBlockValue(
            //     'headlinesize',
            //     'design',
            //     opBlocks[block],
            //     design
            //   ) == '2xl',
          })}
        >
          {returnBlockValue('headline', 'content', opBlocks[block], content)}
        </div>
        <div className="opacity-70 mt-0 text-lg">
          {returnBlockValue('subheadline', 'content', opBlocks[block], content)}
        </div>
      </div>
    </div>
  )
}

export default HeaderBlock
