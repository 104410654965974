export default {
  blockheaderbgcolor: {
    value: '#FFF',
  },
  blockheadertextcolor: {
    value: '#000',
  },
  blockbgcolor: {
    value: '#FFF',
  },
  blocktextcolor: {
    value: '#000',
  },
  headlinesize: {
    value: '2xl',
  },
  cardtitle: {
    value: 'purple',
  },
  cardbgcolor: {
    value: '#FFF',
  },
  cardtextcolor: {
    value: '#000',
  },
  cardbordersize: {
    value: '1px',
  },
  cardbordercolor: {
    value: '',
  },
  generalcardstitle: {
    value: 'blue',
  },
  theme_addbutton: {
    value: '#e4e7eb',
  },
  theme_addbuttontext: {
    value: '#000',
  },
  theme_cardbg: {
    value: '#FFF',
  },
  theme_cardtext: {
    value: '#000',
  },
  theme_cardborder: {
    value: '#DDD',
  },
  theme_multichoicecolor: {
    value: '#2563eb',
  },
  theme_todobgcolor: {
    value: '#21252810',
  },
  theme_todobordercolor: {
    value: '#21252830',
  },
}
