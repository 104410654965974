import Classnames from 'classnames'

import { useState } from 'react'
import { useAtom } from 'jotai'
import { manifestDesign, pageContentDesign, manifestBlocks, userContents } from '../../store'

// helper functions
import { returnValue, returnBlockValue } from '../../utils/storeHelperFunctions'

import Input from '../headless/Input'

const HeaderBlock = ({ id, block, design, content, noStyle }) => {
  // manifest settings
  const [opDesign] = useAtom(manifestDesign)
  const [pageDesign] = useAtom(pageContentDesign)

  const [opBlocks] = useAtom(manifestBlocks)
  const [autoFocus, setAutoFocus] = useState(false)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50 hover:shadow-xl focus-within:shadow-xl focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-blue-100  focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div className="text-xl font-bold">
        {returnBlockValue('title', 'content', opBlocks[block], content)}
      </div>

      <div className="text-lg mt-1 opacity-70">
        {returnBlockValue('desc', 'content', opBlocks[block], content)}
      </div>

      <div className="mt-4 ">
        <Input
          className="py-0 px-0 pb-2 text-xl  focus:outline-none focus:ring-2 focus:ring-transparent focus:border-blue-500"
          blockId={id}
          contentKey="value"
          placeholder={returnBlockValue('placeholder', 'content', opBlocks[block], content)}
          onEnter={() => {}}
          autoFocus={autoFocus}
        />
      </div>

      {returnBlockValue('context', 'content', opBlocks[block], content) && (
        <div className="text-sm mt-4 opacity-50">
          {returnBlockValue('context', 'content', opBlocks[block], content)}
        </div>
      )}
    </div>
  )
}

export default HeaderBlock
