import { useAtom } from 'jotai'

// store
import {
  isBuilder,
  screen,
  screenOpen,
  addBlockIndex,
  inGridBlock,
  gridBlockId,
  gridColumnId,
  gridColumnKey,
} from '../../../store'

import RenderColumnBlocks from './RenderColumnBlocks'

const RenderColumn = ({ id, data, index }) => {
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getAddBlockIndex, setAddBlockIndex] = useAtom(addBlockIndex)
  const [getInGridBlock, setInGridBlock] = useAtom(inGridBlock)
  const [getGridBlockId, setGridBlockId] = useAtom(gridBlockId)
  const [getGridColumnId, setGridColumnId] = useAtom(gridColumnId)
  const [getGridColumnKey, setGridColumnKey] = useAtom(gridColumnKey)

  function addBlock(key) {
    setInGridBlock(true)
    setGridBlockId(id)
    setGridColumnId(data.id)
    setGridColumnKey(key)
    setAddBlockIndex(index)
    setScreen('addblock')
    setScreenOpen(true)
  }

  return (
    <div className="grid grid-cols-1  gap-0 p-6 bg-gray-100 rounded">
      {!data.column_main?.id && (
        <div
          onClick={() => addBlock('column_main')}
          className="p-6 flex items-center justify-center text-gray-400"
        >
          <button className="bg-gray-300 hover:bg-gray-200 text-gray-600 rounded py-2 px-3 font-bold">
            Add Block
          </button>
        </div>
      )}

      {data.column_main?.id && <RenderColumnBlocks block={data.column_main} />}
    </div>
  )
}

export default RenderColumn
