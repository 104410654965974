import React from 'react'
import { useAtom } from 'jotai'
// store

import { RiEditBoxLine } from 'react-icons/ri'

// store
import { screen, screenOpen, blockEditID, blockEditType } from '../../../store'

// sharedBlock components
import Header from '../Header'
import CardsBlock from '../CardsBlock'
import TodosBlock from '../TodosBlock'
import InputBlock from '../InputBlock'
import ButtonBlock from '../ButtonBlock'
import LinkBlock from '../LinkBlock'
import MultiChoiceBlock from '../MultiChoiceBlock'

const Components = {
  header: Header,
  inputblock: InputBlock,
  cardblock: CardsBlock,
  todosblock: TodosBlock,
  ctablock: ButtonBlock,
  multichoiceblock: MultiChoiceBlock,
  linkblock: LinkBlock,
}

const RenderColumnBlocks = ({ block }) => {
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getBlockEditID, setBlockEditID] = useAtom(blockEditID)
  const [getBlockEditType, setBlockEditType] = useAtom(blockEditType)

  return (
    <div className="relative group">
      <div className="opacity-0 group-hover:opacity-100 group absolute bg-white text-gray-700 flex transition duration-300 ease-in-out  -top-4 -right-4 mt-1 mr-1 z-10   items-center space-x-1">
        <div
          onClick={() => {
            setBlockEditID(block.id)
            setBlockEditType(block.block)
            setScreen('editblock')
            setScreenOpen(true)
          }}
          className="ceraFont rounded p-1 shadow border border-gray-700  opacity-20 hover:opacity-100 flex items-center bg-gray-200 bg-opacity-10 hover:bg-opacity-20  cursor-pointer"
        >
          <div className="">
            <RiEditBoxLine />
          </div>
        </div>
      </div>

      <React.Fragment key={`${block.id}`}>
        {Components[block.block] &&
          React.createElement(Components[block.block], {
            id: block.id,
            block: block.block,
            content: block.content,
            design: block.design,
            noStyle: true,
          })}
      </React.Fragment>
    </div>
  )
}

export default RenderColumnBlocks
