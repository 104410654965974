import { Draggable } from 'react-beautiful-dnd'

const TodoItem = ({
  blockId,
  itemId,
  index,
  contentKey,
  className,
  children,
  handle,
  remove,
  style,
}) => {
  return (
    <Draggable key={itemId} draggableId={itemId} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className={'relative group ' + className} style={style}>
            {/* drag handle */}
            <div {...provided.dragHandleProps} className="absolute left-0">
              {handle}
            </div>

            {/* render todos */}
            {children}

            {/* delete todo */}
            {remove}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default TodoItem
