import Classnames from 'classnames'
import { useAtom } from 'jotai'

// helper functions
import { returnValue, returnBlockValue, returnUserContent } from '../../utils/storeHelperFunctions'

// store
import { manifestDesign, pageContentDesign, manifestBlocks, userContents } from '../../store'

// import RenderBlock from './RenderBlock'
import DragWrapper from '../../../_services/components/shared/DragWrapper'

import GridBuilder from './GridBuilder'

const GridBlock = ({ id, block, design, content }) => {
  // manifest settings
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageDesign] = useAtom(pageContentDesign)

  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  // style for nicer grid
  const checkTopEdge = box => box['grid-row-start'] == 1
  const checkLeftEdge = box => box['grid-column-start'] == 1
  const checkTopRightCorner = box => {
    return box['grid-row-start'] == 1 && box['grid-column-start'] + box['grid-column-end'] == 7
  }
  const checkTopLeftCorner = box => {
    return box['grid-row-start'] == 1 && box['grid-column-start'] == 1
  }
  const checkBottomLeftCorner = box => {
    return box['grid-row-end'] == 6 && box['grid-column-start'] == 1
  }
  const checkBottomRightCorner = box => {
    return (
      box['grid-row-end'] + box['grid-row-start'] == 7 &&
      box['grid-column-start'] + box['grid-column-end'] == 7
    )
  }

  // Dynamic classes
  function dynamicClasses(box) {
    return Classnames(`bg-white boxThing p-8 box flex flex-col w-full h-full relative`, {
      hasTopBorder: checkTopEdge(box),
      hasLeftBorder: checkLeftEdge(box),
      hasTopRightRadius: checkTopRightCorner(box),
      hasTopLeftRadius: checkTopLeftCorner(box),
      hasBottomLeftRadius: checkBottomLeftCorner(box),
      hasBottomRightRadius: checkBottomRightCorner(box),
    })
  }

  return (
    <div className="">
      Grid block!
      <div className="">
        {/* <GridBuilder
          gridLayout={returnBlockValue('gridLayout', 'content', opBlocks[block], content)}
          gridLayoutCSS={returnBlockValue('gridLayoutCSS', 'content', opBlocks[block], content)}
          gridColumn={6}
          gridBuilderMode={'layout'}
          isGrid={true}
          pageContentBlocks={returnBlockValue('blocks', 'content', opBlocks[block], content)}
        /> */}

        <div style={{ gap: '0px 0px', padding: '0px' }} className="md:grid grid-cols-6 overflow-hidden">
          {returnBlockValue('gridLayoutCSS', 'content', opBlocks[block], content).map((box, index) => (
            <div
              key={index}
              style={{
                // minHeight: box['grid-row-end'] * 150,
                gridRow: box['grid-row-start'] + '/ span ' + box['grid-row-end'],
                gridColumn: box['grid-column-start'] + '/ span ' + box['grid-column-end'],
              }}
              className={dynamicClasses(box)}
            >
              <DragWrapper>
                Hello!
                {/* <RenderBlock box={box} /> */}
              </DragWrapper>
            </div>
          ))}
        </div>
      </div>
      {/* <pre className="text-xs">
        {JSON.stringify(returnBlockValue('gridLayout', 'content', opBlocks[block], content), null, 2)}
      </pre> */}
    </div>
  )
}

export default GridBlock
