import { Fragment, useState } from 'react'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { request, gql } from 'graphql-request'
import { useEffectOnce } from 'react-use'

// store
import { isBuilder, isLoggedIn, openSignUpModal, userContents, userEmail, isSharedContent } from '../../store'

// component
import { Dialog, Transition } from '@headlessui/react'

const SignUpModal = ({ id }) => {
  const [inputEmail, setEmail] = useState('')
  const [consent, setConsent] = useState(true)
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const [builder] = useAtom(isBuilder)
  const [isSignUpModalOpen, toggleSignUpModal] = useAtom(openSignUpModal)
  const [getLoggedInState, setLoggedInState] = useAtom(isLoggedIn)
  const [getUserEmail, setUserEmail] = useAtom(userEmail)
  const [getShareContent, setShareContent] = useAtom(isSharedContent)

  const [getUserContents, setUserContents] = useAtom(userContents)

  const router = useRouter()

  useEffectOnce(async () => {
    const qs = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    // ## Check for auto sign up
    if (qs.autosignup) {
      if (validateEmail(qs.autosignup)) {
        signUp(qs.autosignup)
      }
    }
  })

  const signUp = async email => {
    setLoading(true)
    setEmailError(false)

    // set cookie wl-email for subdomain.domain
    Cookies.set('user-content-email', email, {
      expires: 14,
      path: router.asPath,
    })

    Cookies.set('wl-submissionConsent', consent, {
      expires: 14,
      path: router.asPath,
    })

    setShareContent(false)

    // todo - permission share

    // graphql version
    const signUpMutation = gql`
      mutation signUp($email: String!, $onepagerId: String!, $content: String!) {
        addUserContent(email: $email, onepagerId: $onepagerId, content: $content) {
          id
          content
        }
      }
    `

    const signUpAndCheckContent = await request('https://onepager.graphcdn.app', signUpMutation, {
      onepagerId: id,
      email: email,
      content: JSON.stringify(getUserContents),
    })

    if (signUpAndCheckContent.addUserContent.content !== 'false') {
      setUserContents(JSON.parse(signUpAndCheckContent.addUserContent.content))
    }

    // save user content id
    Cookies.set('user-content-id', signUpAndCheckContent.addUserContent.id, {
      expires: 14,
      path: router.asPath,
    })

    // check if integration

    const integration = await fetch(`/api/integration?onepager=${id}&email=${email}`).then(response =>
      response.json()
    )
    console.log(integration)

    // remove shareId & autosignup from URL
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname
    window.history.pushState({ path: newurl }, '', newurl)

    setLoggedInState(true)
    setLoading(false)
    setEmailError(false)
    toggleSignUpModal(false)
    setUserEmail(email)
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function loginOnEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (validateEmail(inputEmail)) {
        signUp(inputEmail)
      } else {
        setEmailError(true)
      }
    }
  }

  function closeModal() {
    toggleSignUpModal(false)
  }

  return (
    <Transition appear show={isSignUpModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          {builder && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                This would be the sign up pop up... not available in builder. :)
              </div>
            </Transition.Child>
          )}

          {!builder && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                <h2 className="text-4xl font-bold text-center appTitleItem">Enter in Your Email</h2>
                <p className="text-lg mt-3 text-center">
                  Save Your Copy To Your Existing Account or Automatically Sign Up For a Free Account.
                </p>

                <input
                  autoFocus
                  value={inputEmail}
                  onChange={e => {
                    let emailLower = e.target.value
                    setEmail(emailLower.toLowerCase())
                  }}
                  onKeyDown={loginOnEnter}
                  className="text-xl mt-6 text-center font-medium border-2 border-black shadow-inner mb-2   block w-full p-5"
                  type="text"
                  type="email"
                  placeholder="Enter Your Email Address..."
                />
                <button
                  onClick={() => {
                    signUp(inputEmail)
                  }}
                  className="block p-5 text-2xl font-bold bg-black w-full text-white"
                >
                  <span>Save Changes</span>
                  {/* {isOnepagerForm && <span>Submit & Save Your Changes</span>} */}
                  {/* {!isOnepagerForm && <span>Save Changes</span>} */}
                </button>

                <label
                  name="shareData"
                  className="border  p-2 pr-3 rounded my-2 text-sm block cursor-pointer"
                >
                  <div className=" flex items-center">
                    <input
                      type="checkbox"
                      name="shareData"
                      className="mr-2"
                      checked={consent}
                      onChange={e => {
                        setConsent(e.target.checked)
                      }}
                    />
                    <span className="font-medium">Share my data with the owner of this OnePager.</span>
                  </div>
                </label>

                <div
                  onClick={() => {
                    window.open('https://onepager.io', '_blank')
                  }}
                  className="mt-5 flex justify-center cursor-pointer hover:opacity-75"
                >
                  <span className="flex  items-center rounded ">
                    <img className="h-6 w-6 " src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9" />
                    <span className=" font-bold ml-3">onepager.io</span>
                  </span>
                </div>

                <div className="mt-4">
                  <p className="text-center opacity-75 text-xs mt-3">
                    By signing up you are creating a free OnePager account (or added to your account) to edit
                    and tweak your page at anytime following the{' '}
                    <a
                      href="https://onepager.io/terms-conditions"
                      className="font-medium text-blue-600"
                      target="_blank"
                    >
                      terms
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://onepager.io/privacy-policy"
                      className="font-medium text-blue-600"
                      target="_blank"
                    >
                      privacy
                    </a>{' '}
                    policy &amp; conditions.
                  </p>
                </div>
              </div>
            </Transition.Child>
          )}
        </div>
      </Dialog>
    </Transition>
  )
}

export default SignUpModal
