import Classnames from 'classnames'
import { useAtom } from 'jotai'

// helper functions
import { returnValue, returnBlockValue } from '../../utils/storeHelperFunctions'

import RadioSelect from '../headless/Radio/RadioSelect'
import { RadioGroup } from '@headlessui/react'

// store
import { manifestDesign, pageContentDesign, manifestBlocks } from '../../store'

import { RiCheckFill } from 'react-icons/ri'

const MultiChoiceBlock = ({ id, block, design, content, noStyle }) => {
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  const [pageDesign] = useAtom(pageContentDesign)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50 hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-gray-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div className="text-xl font-bold">
        {returnBlockValue('title', 'content', opBlocks[block], content)}
      </div>

      <div className="text-lg mt-1 opacity-70">
        {returnBlockValue('desc', 'content', opBlocks[block], content)}
      </div>

      <div className={Classnames('mt-4')}>
        <RadioSelect
          blockId={id}
          contentKey="selected"
          className={Classnames('space-y-2 md:space-y-0 md:grid gap-2', {
            'grid-cols-1': returnBlockValue('gridlayout', 'design', opBlocks[block], design) == 'col-1',
            'grid-cols-2': returnBlockValue('gridlayout', 'design', opBlocks[block], design) == 'col-2',
            'grid-cols-3': returnBlockValue('gridlayout', 'design', opBlocks[block], design) == 'col-3',
            'grid-cols-4': returnBlockValue('gridlayout', 'design', opBlocks[block], design) == 'col-4',
          })}
        >
          {returnBlockValue('radio_options', 'content', opBlocks[block], content).map((opt, index) => (
            <RadioGroup.Option
              key={opt.id}
              value={opt.option}
              className={({ checked, active }) => `
                  ${active && 'outline-none '}
                  rounded py-3 px-4 shadow  border cursor-pointer hover:shadow-lg
                `}
              style={{
                backgroundColor: returnValue('theme_cardbg', opDesign, pageDesign),
                color: returnValue('theme_cardtext', opDesign, pageDesign),
                borderColor: returnValue('theme_cardborder', opDesign, pageDesign),
              }}
            >
              {({ checked, active }) => (
                <div className="flex items-center justify-between">
                  <div className="">
                    <div className="font-bold text-lg">{opt.headline}</div>
                    <div className="text-sm">{opt.subheadline}</div>
                  </div>
                  {checked && (
                    <div
                      style={{
                        backgroundColor: returnValue('theme_multichoicecolor', opDesign, pageDesign),
                      }}
                      className="rounded-full p-2  text-white"
                    >
                      <RiCheckFill />
                    </div>
                  )}
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioSelect>
      </div>
    </div>
  )
}

export default MultiChoiceBlock
