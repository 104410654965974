import { useAtom } from 'jotai'
import produce from 'immer'
import { userContents } from '../../../store'

const TodosTick = ({
  contentKey,
  blockId,
  index,
  completed,
  className,
  completedBlock,
  notCompletedBlock,
  style,
}) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  function toggleCompleted() {
    const nextState = produce(getUserContents, draft => {
      draft[blockId][contentKey][index].completed = !completed
    })
    setUserContents(nextState)
  }

  return (
    <div onClick={toggleCompleted} className={className} style={style}>
      {/* render completed */}
      {completed && completedBlock}
      {/* render not completed */}
      {!completed && notCompletedBlock}
    </div>
  )
}

export default TodosTick
