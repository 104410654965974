import { useAtom } from 'jotai'
import { cardsShowModal, cardsEditId, cardsBlockEditId } from '../../../store'

const CardModalOverlay = ({ className }) => {
  // card
  const [showCardModal, toggleCardModal] = useAtom(cardsShowModal)
  const [getCardEditId, setCardEditId] = useAtom(cardsEditId)
  const [getCardBlockEditId, setCardBlockEditId] = useAtom(cardsBlockEditId)

  function closeModal() {
    toggleCardModal(false)
    setCardEditId('')
    setCardBlockEditId('')
  }

  return (
    <div
      onClick={closeModal}
      className={
        'transition cursor-pointer fixed top-0 left-0 h-full w-full ' +
        className
      }
    ></div>
  )
}

export default CardModalOverlay
