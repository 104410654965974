import { useAtom } from 'jotai'

// helper functions
import { returnValue } from '../../_services/utils/storeHelperFunctions'
import StaticContentWrapper from '../../_services/components/shared/StaticContentWrapper'

// store
import {
  isBuilder,
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
} from '../../_services/store'

const CopyHeader = () => {
  const [builder] = useAtom(isBuilder)
  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  return (
    <div
      className="opr-top-header flex items-center justify-between px-8 h-16 sticky top-0 z-30 border-b border-black border-opacity-10"
      style={{
        backgroundColor: returnValue('bgcolor_nav', opDesign, pageDesign),
      }}
    >
      <div className="font-medium">
        <StaticContentWrapper contentKey="brandname">
          {returnValue('brandname', opStatic, pageStatic)}
        </StaticContentWrapper>
      </div>
      {/* <div className="opacity-60">#something</div> */}
    </div>
  )
}

export default CopyHeader
