import { useAtom } from 'jotai'
import produce from 'immer'
import { nanoid } from 'nanoid'

import { userContents, todosFocusId } from '../../../store'

const TodoAdd = ({ contentKey, blockId, className, text, newTodoText, style }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)
  // todo focus
  const [getTodoFocusId, setTodoFocusId] = useAtom(todosFocusId)

  function addNewTodo() {
    const newTodoId = 'todo-' + nanoid()

    const nextState = produce(getUserContents, draft => {
      if (draft[blockId]) {
        draft[blockId][contentKey].push({
          id: newTodoId,
          text: newTodoText ? newTodoText : '',
          completed: false,
        })
      } else {
        draft[blockId] = {
          [contentKey]: [
            {
              id: newTodoId,
              text: newTodoText ? newTodoText : '',
              completed: false,
            },
          ],
        }
      }
    })
    setUserContents(nextState)

    setTodoFocusId(newTodoId)
  }

  return (
    <div onClick={addNewTodo} className={className} style={style}>
      {text}
    </div>
  )
}

export default TodoAdd
