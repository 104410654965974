import Classnames from 'classnames'
import { useAtom } from 'jotai'

// helper functions
import { returnValue, returnBlockValue, returnUserContent } from '../../utils/storeHelperFunctions'

// components
import CardsWrapper from '../headless/Card/CardsWrapper'
import CardItem from '../headless/Card/CardItem'
import CardAdd from '../headless/Card/CardAdd'
import CardModal from '../headless/Card/CardModal'
import CardModalTitle from '../headless/Card/CardModalTitle'
import CardModalDesc from '../headless/Card/CardModalDesc'
import CardModalDeleteButton from '../headless/Card/CardModalDeleteButton'
import CardModalCloseButton from '../headless/Card/CardModalCloseButton'
import CardModalOverlay from '../headless/Card/CardModalOverlay'

// store
import { manifestDesign, pageContentDesign, manifestBlocks, userContents } from '../../store'

const CardsBlock = ({ id, block, design, content, noStyle }) => {
  // manifest settings
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageDesign] = useAtom(pageContentDesign)

  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50  hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div className="text-xl font-bold">
        {returnBlockValue('title', 'content', opBlocks[block], content)}
      </div>

      <div className="text-lg mt-1 opacity-70">
        {returnBlockValue('desc', 'content', opBlocks[block], content)}
      </div>

      <div className="mt-4 ">
        <CardsWrapper contentKey="cards" blockId={id} className="space-y-3 cursor-default">
          {returnUserContent('cards', id, getUserContents)?.map((block, index) => (
            <CardItem
              key={block.id}
              itemId={block.id}
              blockId={id}
              index={index}
              contentKey="cards"
              className={Classnames(
                'py-3 px-5 rounded  transition-all duration-150 ease-out  shadow-md ring-1 ring-gray-300 ring-offset-2 ring-offset-gray-100 hover:shadow-lg '
              )}
              style={{
                backgroundColor: returnValue('theme_cardbg', opDesign, pageDesign),
                color: returnValue('theme_cardtext', opDesign, pageDesign),
                borderColor: returnValue('theme_cardborder', opDesign, pageDesign),
              }}
            >
              <div className="font-medium text-lg">{block.text}</div>
              {block.desc && <div className="text-sm opacity-70">{block.desc}</div>}
            </CardItem>
          ))}
        </CardsWrapper>

        <div className="mt-4">
          <CardAdd
            blockId={id}
            contentKey="cards"
            text={returnBlockValue('addcardbutton', 'content', opBlocks[block], content)}
            newText={returnBlockValue('newcardtext', 'content', opBlocks[block], content)}
            style={
              {
                // backgroundColor: returnValue('theme_addbutton', opDesign, pageDesign),
                // color: returnValue('theme_addbuttontext', opDesign, pageDesign),
              }
            }
            className="inline-block  mt-2 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 ring-offset-gray-50 cursor-pointer bg-white text-gray-800 hover:bg-blue-500 hover:text-blue-50 hover:ring-offset-blue-300 hover:ring-blue-600 font-bold py-1.5 px-4 rounded"

            // className={Classnames('inline-block cursor-pointer bg-black text-white font-bold rounded', {
            //   'text-sm py-2 px-4 ':
            //     returnBlockValue('addbuttonsize', 'design', opBlocks[block], design) == 'default',
            //   'text-lg py-2 px-4 ':
            //     returnBlockValue('addbuttonsize', 'design', opBlocks[block], design) == 'lg',
            //   'text-xs py-2 px-4 ':
            //     returnBlockValue('addbuttonsize', 'design', opBlocks[block], design) == 'sm',
            // })}
          />
        </div>

        <CardModal
          id={id}
          contentKey="cards"
          className="bg-white border-2 border-gray-600 shadow-xl rounded p-12 relative"
          width="700px"
          overlay={<CardModalOverlay id={id} contentKey="cards" className="bg-gray-800 bg-opacity-75" />}
          deleteButton={
            <CardModalDeleteButton
              id={id}
              contentKey="cards"
              text="Remove"
              className="cursor-pointer hover:bg-red-400 font-bold hover:text-white py-2 px-3 rounded text-red-400"
            />
          }
        >
          <div className="mb-3 text-black">Title</div>
          <CardModalTitle
            id={id}
            contentKey="cards"
            placeholder="Text..."
            className="text-xl font-bold text-black"
          />

          <div className="mt-4 mb-3 text-black">Desc</div>
          <CardModalDesc id={id} contentKey="cards" placeholder="desc..." className="mt-2 text-black" />
        </CardModal>
      </div>
    </div>
  )
}

export default CardsBlock
