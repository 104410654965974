import React from 'react'
import { resetServerContext } from 'react-beautiful-dnd'

import getOnepagerData from '../utils/getOnepagerData'
import NotFound from '../components/NotFound'

import {
  Grid,
  CardScroller,
  CenterScroller,
  SideScroller,
  JotaiStoreWrapper,
  Manifests,
} from '@onepager/onepagers'

const Components = {
  grid: Grid,
  cardscroller: CardScroller,
  centerscroller: CenterScroller,
  sidescroller: SideScroller,
}

const RenderPage = props => {
  // merge manifest with theme manifest for design based on theme ( light / dark / etc )
  if (props.id) {
    return (
      <JotaiStoreWrapper
        manifest={Manifests[props.type]}
        content={props.content}
        isBuilder={false}
        isEmbed={false}
      >
        {React.createElement(Components[props.type], props)}
      </JotaiStoreWrapper>
    )
  } else {
    return <NotFound />
  }
}

export default RenderPage

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' }
}

export async function getStaticProps(context) {
  resetServerContext()

  // let path = context.params.id
  // let getId = path.substr(path.lastIndexOf('-') + 1)
  // return getOnepagerData(getId)

  return getOnepagerData(context.params.id)
}
