import { useRef } from 'react'
import { useAtom } from 'jotai'
import produce from 'immer'
import { find, search } from 'simple-object-query'

import Classnames from 'classnames'

// helper functions
import { returnUserContent } from '../../utils/storeHelperFunctions'

// store
import { userContents } from '../../store'

// components
import TextareaAutosize from 'react-textarea-autosize'

const HeadlessInput = ({
  placeholder,
  className,
  style,
  blockId,
  contentKey,
  updateValue,
  autoFocus,
  onEnter,
  onFocus,
  onBlur,
  preventEnter,
}) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  const textRef = useRef(null)

  return (
    <div className="">
      <TextareaAutosize
        ref={textRef}
        className={Classnames(
          'block blockInput border-b-2 border-black resize-none w-full bg-transparent outline-none  placeholder:opacity-80',
          className
        )}
        style={{ ...style }}
        value={returnUserContent(contentKey, blockId, getUserContents)}
        placeholder={placeholder}
        onChange={e => {
          const nextState = produce(getUserContents, draft => {
            if (draft[blockId]) {
              draft[blockId][contentKey] = e.target.value
            } else {
              draft[blockId] = { [contentKey]: e.target.value }
            }
          })
          setUserContents(nextState)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onEnter()
            if (preventEnter) {
              e.preventDefault()
            }
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
}

export default HeadlessInput
