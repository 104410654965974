import { RadioGroup } from '@headlessui/react'

import { useAtom } from 'jotai'
import { userContents } from '../../../store'

import produce from 'immer'

// helper functions
import { returnUserContent } from '../../../utils/storeHelperFunctions'

const RadioSelect = ({ blockId, contentKey, className, children }) => {
  const [getUserContents, setUserContents] = useAtom(userContents)

  return (
    <RadioGroup
      value={returnUserContent(contentKey, blockId, getUserContents)}
      onChange={opt => {
        const nextState = produce(getUserContents, draft => {
          draft[blockId][contentKey] = opt
        })
        setUserContents(nextState)
      }}
      className={className}
    >
      {children}
    </RadioGroup>
  )
}

export default RadioSelect
