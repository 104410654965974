import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffectOnce } from 'react-use'
import { request, gql } from 'graphql-request'
import Classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { RiExternalLinkLine, RiArrowRightLine, RiCloseCircleLine } from 'react-icons/ri'

function PublicHelloBar({ id }) {
  const router = useRouter()

  const [showHelloBar, toggleHellowBar] = useState(false)

  const [getTitle, setTitle] = useState('TEXT !')
  const [getCtaText, setCtaText] = useState('Buton')
  const [getButtonLink, setButtonLink] = useState('link')
  const [getBackgroundColor, setBackgroundColor] = useState('#000')
  const [getTextColor, setTextColor] = useState('white')

  useEffectOnce(async () => {
    if (!Cookies.get('hide-promo-bar')) {
      const getPromoGql = gql`
        query getPromo($id: String!) {
          getPromo(onepagerId: $id) {
            id
            type
            backgroundColor
            textColor
            title
            subtitle
            link
            ctaText
          }
        }
      `

      const getPromoData = await request('https://onepager.graphcdn.app', getPromoGql, {
        id: id,
      })

      if (getPromoData.getPromo.id !== 'not-found') {
        setTitle(getPromoData.getPromo.title)
        setCtaText(getPromoData.getPromo.ctaText)
        setButtonLink(getPromoData.getPromo.link)
        setBackgroundColor(getPromoData.getPromo.backgroundColor)
        setTextColor(getPromoData.getPromo.textColor)

        toggleHellowBar(true)
      }
    }
  })

  return (
    <>
      <AnimatePresence initial={false} exitBeforeEnter>
        {showHelloBar && (
          <motion.div
            id="promoBar"
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ delay: 0.3, duration: 0.15 }}
            variants={{
              hidden: {
                opacity: 0,
                y: -48,
              },
              visible: {
                opacity: 1,
                y: 0,
              },
              exit: {
                opacity: 0,
                height: 0,
                y: -48,
              },
            }}
            style={{ backgroundColor: getBackgroundColor, zIndex: 999999 }}
            className="bg-white shadow-lg fixed top-0 left-0 w-full border-b-2 border-t-2 border-black  border-opacity-20"
          >
            <div
              className={Classnames('container relative   flex items-center ', {
                'max-w-7xl justify-center mx-auto': true,
              })}
            >
              <div className="flex flex-col md:flex-row items-center font-bold text-white text-lg py-2.5">
                {getTitle && (
                  <div
                    className={Classnames('flex md:items-center mb-2 pr-12 pl-5 md:pl-0 md:pr-0 md:mb-0', {
                      'text-black': getTextColor == 'black',
                      'text-white': getTextColor == 'white',
                    })}
                  >
                    <RiArrowRightLine className="mr-2 opacity-60 mt-2 md:mt-0" />
                    {getTitle}
                  </div>
                )}

                {getCtaText !== 'undefined' && (
                  <button
                    onClick={() => {
                      window.open(getButtonLink, '_blank')
                    }}
                    className=" inline-flex items-center rounded text-white ml-4 py-2 md:py-0 px-3 font-bold bg-black"
                  >
                    {getCtaText}
                    <RiExternalLinkLine className="ml-2" />
                  </button>
                )}
              </div>
              {/* close bar */}
              <div
                onClick={() => {
                  Cookies.set('hide-promo-bar', true, {
                    expires: 14,
                    path: router.asPath,
                  })
                  toggleHellowBar(false)
                }}
                className={Classnames(
                  'absolute right-2 md:right-0 top-2 md:top-auto cursor-pointer hover:text-opacity-75 text-2xl',
                  {
                    'text-black': getTextColor == 'black',
                    'text-white': getTextColor == 'white',
                  }
                )}
              >
                <RiCloseCircleLine />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default PublicHelloBar
