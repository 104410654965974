// return value page static content
export function returnValue(key, manifest, content) {
  if (manifest[key]?.value === undefined) {
    return false
  } else {
    return content[key] ? content[key] : manifest[key].value
  }
}

// return value of block content value
export function returnBlockValue(key, type, manifest, content) {
  if (manifest[type][key]?.value === undefined) {
    return false
  } else {
    return content[key] ? content[key] : manifest[type][key].value
  }
}

// return user content
export function returnUserContent(key, id, data) {
  if (data && Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype) {
    // is empty
    return []
  } else {
    // console.log(data[id])
    return data[id]?.[key]
  }
}
