import Classnames from 'classnames'
import { useAtom } from 'jotai'
import {
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  isBuilder,
  isEmbed,
} from '../_services/store'

import { returnValue } from '../_services/utils/storeHelperFunctions'

// shared components
import StaticContentWrapper from '../_services/components/shared/StaticContentWrapper'
import Video from '../_services/components/shared/Video'
import OnepagerFooter from '../_services/components/shared/OnepagerFooter'

// sharedBlock renderer
import BlockRenderer from '../_services/components/sharedBlocks/BlockRenderer'

// local components
import SignupBlock from './components/SignupBlock'

const Render = ({ id }) => {
  const [builder] = useAtom(isBuilder)
  const [embed] = useAtom(isEmbed)

  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  return (
    <div
      className="pb-12 opr-mainarea min-h-screen"
      style={{
        backgroundColor: returnValue('pagebg', opDesign, pageDesign),
      }}
    >
      {/* HEADER */}
      <div
        className={Classnames(' border-b-2 border-black border-opacity-25 relative z-10', {
          'px-12': embed,
        })}
        style={{
          backgroundColor: returnValue('bgcolor', opDesign, pageDesign),
          color: returnValue('maintextcolor', opDesign, pageDesign),
        }}
      >
        {/* background image */}
        {returnValue('bgimgurl', opDesign, pageDesign) && (
          <div
            className="absolute top-0 left-0 z-0 h-full w-full  bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${returnValue('bgimgurl', opDesign, pageDesign)})`,
              opacity: returnValue('bgimgopacity', opDesign, pageDesign),
            }}
          ></div>
        )}

        <div className="container max-w-6xl mx-auto">
          <div className="px-8 md:px-0 md:flex items-center relative z-10 pt-12 pb-28">
            {/* headline / subheadline */}
            <div
              className={Classnames({
                'md:w-3/4 md:pr-12':
                  !returnValue('videourl', opStatic, pageStatic) &&
                  !returnValue('imgurl', opStatic, pageStatic),
                'md:w-1/2 md:pr-12':
                  returnValue('videourl', opStatic, pageStatic) ||
                  returnValue('imgurl', opStatic, pageStatic),
              })}
            >
              <h1
                className={Classnames('font-bold text-6xl', {
                  appTitleItem: returnValue('herofont', opDesign, pageDesign) == 'sans',
                })}
                style={{
                  color: returnValue('headlinetextcolor', opDesign, pageDesign),
                }}
              >
                <StaticContentWrapper contentKey="headline">
                  {returnValue('headline', opStatic, pageStatic)}
                </StaticContentWrapper>{' '}
              </h1>
              <div className="mt-4 mb-6 text-xl font-medium">
                <StaticContentWrapper contentKey="subheadline">
                  {returnValue('subheadline', opStatic, pageStatic)}
                </StaticContentWrapper>
              </div>
            </div>

            <div
              className={Classnames({
                'md:w-1/2':
                  returnValue('videourl', opStatic, pageStatic) ||
                  returnValue('imgurl', opStatic, pageStatic),
              })}
            >
              {/* video */}
              {returnValue('videourl', opStatic, pageStatic) && (
                <Video url={returnValue('videourl', opStatic, pageStatic)} classNames="mb-8" />
              )}

              {/* img */}
              {returnValue('imgurl', opStatic, pageStatic) && (
                <img
                  src={returnValue('imgurl', opStatic, pageStatic)}
                  alt=""
                  className="rounded shadow-lg mb-8"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={Classnames(' bg-white shadow rounded -mt-24 relative z-10 overflow-hidden opr-container', {
          'mx-12': embed,
          'container max-w-6xl mx-auto': !embed,
        })}
        style={{
          backgroundColor: returnValue('blockbgcolor', opDesign, pageDesign),
          color: returnValue('blocktextcolor', opDesign, pageDesign),
        }}
      >
        {!embed && <SignupBlock />}

        <div className="p-4 md:p-12 space-y-4">
          <BlockRenderer />
        </div>
      </div>
      {!builder || (!embed && <OnepagerFooter />)}
    </div>
  )
}

export default Render
