import React from 'react'
import { useAtom } from 'jotai'
import produce from 'immer'

// lodash # builder

// store
import {
  manifestBlocks,
  pageContentBlocks,
  isBuilder,
  screen,
  screenOpen,
  blockEditID,
  blockEditType,
  userContents,
  addBlockIndex,
  addBlockId,
} from '../../store'

import {
  RiEditBoxLine,
  RiFileCopyLine,
  RiDeleteBin6Line,
  RiArrowUpLine,
  RiArrowDownLine,
  RiAddCircleLine,
} from 'react-icons/ri'

const BlockRenderer = ({ Components, block, index }) => {
  const [builder] = useAtom(isBuilder)
  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getBlockEditID, setBlockEditID] = useAtom(blockEditID)
  const [getBlockEditType, setBlockEditType] = useAtom(blockEditType)
  const [getAddBlockIndex, setAddBlockIndex] = useAtom(addBlockIndex)
  const [getAddBlockId, setAddBlockId] = useAtom(addBlockId)
  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)
  const [manifest] = useAtom(manifestBlocks)
  const [getUserContents, setUserContents] = useAtom(userContents)

  function cloneBlock() {
    const newBlockID = 'block-' + guidGenerator()
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.id == block.id
    })
    let cloneBlockData = _.cloneDeep(getPageBlocks[getIndex])
    cloneBlockData.id = newBlockID

    const nextState = produce(getPageBlocks, draft => {
      draft = draft.splice(index + 1, 0, cloneBlockData)
    })
    setPageBlocks(nextState)

    // add user contents blank - if editable block
    if (manifest[cloneBlockData.block].settings.type == 'editable') {
      const nextUserContentsState = produce(getUserContents, draft => {
        // check if block has user content - clone that
        if (_.isEmpty(getUserContents[getPageBlocks[getIndex].id])) {
          draft[cloneBlockData.id] = manifest[cloneBlockData.block].usercontent
        } else {
          // check if todos / cards -
          let cloneUserContents = _.cloneDeep(getUserContents[getPageBlocks[getIndex].id])
          for (const [key, value] of Object.entries(cloneUserContents)) {
            // check if array
            if (Array.isArray(value)) {
              value.forEach(blk => {
                // update id
                if (blk.id) {
                  blk.id = 'id-' + guidGenerator()
                }
              })
            }
          }

          draft[cloneBlockData.id] = cloneUserContents
        }
      })
      setUserContents(nextUserContentsState)
    }
  }

  function deleteBlock() {
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.id == block.id
    })

    const nextState = produce(getPageBlocks, draft => {
      draft.splice(getIndex, 1)
    })
    setPageBlocks(nextState)
  }

  function moveBlockUp() {
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.id == block.id
    })

    // check if can move up one more
    if (getPageBlocks[getIndex - 1]) {
      const newItemsOrder = reorder(getPageBlocks, getIndex, getIndex - 1)
      setPageBlocks(newItemsOrder)
    } else {
      // console.log('first block...')
    }
  }

  function moveBlockDown() {
    const getIndex = _.findIndex(getPageBlocks, function (o) {
      return o.id == block.id
    })

    // check if can move down one more
    if (getPageBlocks[getIndex + 1]) {
      const newItemsOrder = reorder(getPageBlocks, getIndex, getIndex + 1)
      setPageBlocks(newItemsOrder)
    } else {
      // console.log('last block...')
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
  }

  if (builder) {
    return (
      <div className="relative group">
        {/* hover tools */}

        <div className="opacity-0 group-hover:opacity-100 absolute bg-white text-gray-700 flex transition duration-300 ease-in-out  -top-14 right-0 mt-1 mr-1 z-10  rounded p-1 shadow border border-gray-700  items-center space-x-1">
          <div
            onClick={() => {
              setBlockEditID(block.id)
              setBlockEditType(block.block)
              setScreen('editblock')
              setScreenOpen(true)
            }}
            className="ceraFont flex items-center bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
          >
            <div className="">
              <RiEditBoxLine />
            </div>
            <div className="text-sm ml-1.5 font-medium">Edit Block</div>
          </div>
          {/* <Tooltip showDelay={500} content="Clone Block"> */}
          <div
            onClick={() => {
              cloneBlock()
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
          >
            <RiFileCopyLine />
          </div>
          {/* </Tooltip> */}

          {/* <Tooltip showDelay={500} content="Delete Block"> */}
          <div
            onClick={() => {
              var r = confirm('Delete block - are you sure?')
              if (r == true) {
                deleteBlock()
              }
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
          >
            <RiDeleteBin6Line />
          </div>
          {/* </Tooltip> */}

          {/* <Tooltip showDelay={500} content="Move Block Up"> */}
          <div
            onClick={() => {
              moveBlockUp()
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
          >
            <RiArrowUpLine />
          </div>
          {/* </Tooltip> */}
          {/* <Tooltip showDelay={500} content="Move Block Down"> */}
          <div
            onClick={() => {
              moveBlockDown()
            }}
            className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
          >
            <RiArrowDownLine />
          </div>
          {/* </Tooltip> */}
          {/* <Tooltip showDelay={500} content="Add Block After">
            <div
              onClick={() => {
                setAddBlockIndex(index)
                setScreen('addblock')
                setScreenOpen(true)
              }}
              className="bg-gray-200 bg-opacity-10 hover:bg-opacity-20  p-1.5 rounded cursor-pointer"
            >
              <RiAddCircleLine />
            </div>
          </Tooltip> */}
        </div>

        <React.Fragment key={block.id}>
          {Components[block.block] &&
            block.boxLayoutId == null &&
            React.createElement(Components[block.block], {
              id: block.id,
              block: block.block,
              content: block.content,
              design: block.design,
              index: index,
            })}
        </React.Fragment>

        <div className="opacity-0  hover:opacity-100  absolute -bottom-2 left-0 z-10 w-full flex items-center justify-center">
          <div className="h-1 w-full bg-blue-600 rounded "></div>
          <div
            onClick={() => {
              setAddBlockId(block.id)
              setAddBlockIndex(index)
              setScreen('addblock')
              setScreenOpen(true)
            }}
            className="bg-blue-600 text-white shadow hover:shadow-xl hover:bg-blue-500 rounded-full p-1 text-xl cursor-pointer  absolute"
          >
            <RiAddCircleLine />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <React.Fragment key={block.id}>
        {Components[block.block] &&
          React.createElement(Components[block.block], {
            id: block.id,
            block: block.block,
            content: block.content,
            design: block.design,
            index: index,
          })}
      </React.Fragment>
    )
  }
}

export default BlockRenderer
