import { Fragment, useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'

import Cookies from 'js-cookie'

// store
import { openShareModal } from '../../store'

// component
import { Dialog, Transition } from '@headlessui/react'

const ShareModal = ({ id }) => {
  const [shareURL, setShareURL] = useState('')
  const [isShareModalOpen, toggleShareModal] = useAtom(openShareModal)
  const router = useRouter()

  useEffect(async () => {
    if (Cookies.get('user-content-id')) {
      setShareURL(
        'https://' + window.location.host + router.asPath + '?shareId=' + Cookies.get('user-content-id')
      )
    }
  }, [isShareModalOpen])

  function closeModal() {
    toggleShareModal(false)
  }

  return (
    <Transition appear show={isShareModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <h2 className="text-4xl font-bold text-center appTitleItem">Share</h2>
              <p className="text-sm mt-3 text-center">
                Copy this URL, and anyone with the link will be able to see your edited content on this
                onepager. Other people will not be able to edit the content.
              </p>

              <input
                autoFocus
                value={shareURL}
                onChange={e => {}}
                className="text-xl mt-6 text-center font-medium border-2 border-black shadow-inner mb-2   block w-full p-5"
                type="text"
                placeholder="..."
              />

              <div
                onClick={() => {
                  window.open('https://onepager.io', '_blank')
                }}
                className="mt-5 flex justify-center cursor-pointer hover:opacity-75"
              >
                <span className="flex  items-center rounded ">
                  <img className="h-6 w-6 " src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9" />
                  <span className=" font-bold ml-3">onepager.io</span>
                </span>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ShareModal
