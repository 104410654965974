import { useAtom } from 'jotai'
import produce from 'immer'

// store
import {
  userContents,
  cardsShowModal,
  cardsEditId,
  cardsBlockEditId,
} from '../../../store'

const CardModalDeleteButton = ({ id, contentKey, text, className }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  // card
  const [showCardModal, toggleCardModal] = useAtom(cardsShowModal)
  const [getCardEditId, setCardEditId] = useAtom(cardsEditId)
  const [getCardBlockEditId, setCardBlockEditId] = useAtom(cardsBlockEditId)

  function deleteCard() {
    const getIndex = _.findIndex(
      getUserContents[getCardBlockEditId][contentKey],
      function (o) {
        return o.id == getCardEditId
      }
    )

    const nextState = produce(getUserContents, draft => {
      draft[getCardBlockEditId][contentKey].splice(getIndex, 1)
    })
    setUserContents(nextState)

    toggleCardModal(false)
    setCardEditId('')
    setCardBlockEditId('')
  }

  return (
    <div onClick={deleteCard} className={className}>
      {text}
    </div>
  )
}

export default CardModalDeleteButton
