const CatchError = ({ error, resetErrorBoundary }) => {
  return (
    <div className="min-h-screen bg-teal-50 p-12 ">
      <div
        className="p-12 bg-teal-100 container max-w-xl mx-auto shadow-xl shadow-teal-100 border-2 border-teal-400 space-y-4 rounded"
        role="alert"
      >
        <h2 className="text-2xl font-medium">ERROR: Something went wrong:</h2>
        <pre className="p-3 bg-teal-200 rounded">{error.message}</pre>

        <button className="bg-teal-700 text-white font-bold py-2 px-3 rounded" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  )
}

export default CatchError
