import { useState, useEffect } from 'react'
import Classnames from 'classnames'

import GridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Underlay from './builder/Underlay'
import PreviewBox from './PreviewBox'
import Grid from './Grid'

// import { grid } from 'grid-template-parser'

import RenderBlock from './RenderBlock'
import DragWrapper from '../../_services/components/shared/DragWrapper'

import { useAtom } from 'jotai'
import {
  gridLayout,
  gridColumn,
  isGrid,
  pageContentBlocks,
  gridBuilderMode,
  gridLayoutCSS,
  screen,
  screenOpen,
  addBlockIndex,
  gridBlockAddIndex,
} from '../../_services/store'

import { nanoid } from 'nanoid'

const GridBuilder = () => {
  // const [boxes, setBoxes] = useState([])
  const [canEdit, toggleEdit] = useState(true)
  const [gridRows, setGridRows] = useState(6)
  const [getIsGrid, setIsGrid] = useAtom(isGrid)

  const [getGridLayout, setGridLayout] = useAtom(gridLayout)
  const [getGridLayoutCSS, setGridLayoutCSS] = useAtom(gridLayoutCSS)
  const [getGridColumn, setGridColumn] = useAtom(gridColumn)
  const [getGridBuilderMode, setGridBuilderMode] = useAtom(gridBuilderMode)

  const [getScreen, setScreen] = useAtom(screen)
  const [getScreenOpen, setScreenOpen] = useAtom(screenOpen)
  const [getAddBlockIndex, setAddBlockIndex] = useAtom(addBlockIndex)
  const [getGridBlockAddIndex, setGridBlockAddIndex] = useAtom(gridBlockAddIndex)

  const [getPageBlocks, setPageBlocks] = useAtom(pageContentBlocks)

  useEffect(() => {
    setIsGrid(true)
  }, [])

  // useEffect(() => {
  //   console.log(getPageBlocks)
  // }, [getPageBlocks])

  useEffect(() => {
    // convert layout to css grid
    let gridObj = []

    getGridLayout.forEach(box => {
      gridObj.push({
        i: box.i,
        'grid-row-start': box.y ? box.y + 1 : 1,
        'grid-column-start': box.x ? box.x + 1 : 1,
        'grid-row-end': box.h,
        'grid-column-end': box.w,
      })
    })

    setGridLayoutCSS(gridObj)
  }, [getGridLayout])

  function onLayoutChange(currentLayout, allLayouts) {
    setGridLayout(currentLayout)
  }

  function addBoxEnd() {
    setGridBlockAddIndex(null)
    setAddBlockIndex(null)

    setGridBuilderMode('layout')
    setScreen('addblock')
    setScreenOpen(true)
  }

  return (
    <>
      <div
        className={Classnames('relative z-10 -mt-16 overflow-hidden max-w-6xl mx-auto bg-white rounded-md', {
          'border-black border': getGridBuilderMode == 'layout',
        })}
        style={{ minHeight: gridRows * 250 + 'px' }}
      >
        {getGridBuilderMode == 'layout' && (
          <>
            <GridLayout
              useCSSTransforms={false}
              preventCollision={true}
              isBounded={false}
              className="layout "
              margin={[0, 0]}
              cols={getGridColumn}
              rowHeight={250}
              width={1152}
              isDraggable={canEdit}
              isResizable={canEdit}
              // onDrop={onDrop}
              // isDroppable={true}
              droppingItem={{ i: '__dropping-elem__', h: 2, w: 6 }}
              onLayoutChange={onLayoutChange}
              compactType={null}
              // compactType={'horizontal'}
              autoSize={true}
              draggableHandle=".dragHandle"
              onDragStop={v => {
                // console.log(v)
              }}
              onResizeStop={v => {
                // console.log(v)
              }}
            >
              {getGridLayout.map((box, index) => (
                <div
                  data-grid={box}
                  key={box.i}
                  className="bg-white box group relative z-50 border border-black"
                >
                  <div className="w-full h-full p-7 overflow-y-auto">
                    <DragWrapper>
                      <RenderBlock box={box} />
                    </DragWrapper>
                  </div>
                </div>
              ))}
            </GridLayout>
            <Underlay />
          </>
        )}

        {getGridBuilderMode == 'edit' && <Grid />}
      </div>

      <div className="pb-16 pt-12">
        <div className="mx-auto flex items-center justify-center">
          <div
            onClick={() => addBoxEnd()}
            className="bg-black cursor-pointer text-white py-2 px-3 rounded font-medium"
          >
            Add Block
          </div>
        </div>
      </div>
    </>
  )
}

export default GridBuilder
