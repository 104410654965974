import { useAtom } from 'jotai'

// store
import { isSharedContent } from '../../store'

const ShareHeader = () => {
  const [getShareContent] = useAtom(isSharedContent)

  if (getShareContent) {
    return (
      <div className="h-12 bg-white py-2 px-5 flex items-center justify-center bg-brand-yellow bg-gradient-to-r from-brand-yellow via-yellow-400 to-yellow-500 border-b-2 border-black border-opacity-10 shadow">
        <div className="font-medium">Viewing Shared Content</div>
      </div>
    )
  } else {
    return null
  }
}

export default ShareHeader
