import { useAtom } from 'jotai'
import { userContents, cardsEditId, cardsBlockEditId } from '../../../store'
import produce from 'immer'

// component
import TextareaAutosize from 'react-textarea-autosize'

const CardModalDesc = ({ contentKey, placeholder, className }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  // card
  const [getCardEditId] = useAtom(cardsEditId)
  const [getCardBlockEditId] = useAtom(cardsBlockEditId)

  function getCardData(key) {
    const getIndex = getUserContents[getCardBlockEditId][contentKey].findIndex(
      o => o.id == getCardEditId
    )

    return getUserContents[getCardBlockEditId][contentKey][getIndex][key]
  }
  return (
    <TextareaAutosize
      className={
        'block resize-none w-full bg-transparent border border-gray-300 rounded py-2 px-3 outline-none' +
        className
      }
      value={getCardData('desc')}
      placeholder={placeholder}
      onChange={e => {
        const getIndex = getUserContents[getCardBlockEditId][
          contentKey
        ].findIndex(o => o.id == getCardEditId)

        const nextState = produce(getUserContents, draft => {
          draft[getCardBlockEditId][contentKey][getIndex].desc = e.target.value
        })
        setUserContents(nextState)
      }}
    />
  )
}

export default CardModalDesc
