import { useAtom } from 'jotai'
import produce from 'immer'

import { userContents } from '../../store'

import { DragDropContext } from 'react-beautiful-dnd'

const DragWrapper = ({ children }) => {
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)

  function handleDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const newItemsOrder = reorder(
        getUserContents[source.droppableId][result.type],
        source.index,
        destination.index
      )

      const nextState = produce(getUserContents, draft => {
        draft[source.droppableId][result.type] = newItemsOrder
      })
      setUserContents(nextState)
    } else {
      const newMovedBlocks = move(
        getUserContents[source.droppableId][result.type],
        getUserContents[destination.droppableId][result.type],
        source,
        destination
      )

      const nextState = produce(getUserContents, draft => {
        Object.keys(newMovedBlocks).map((blockId, index) => {
          draft[blockId][result.type] = newMovedBlocks[blockId]
        })
      })
      setUserContents(nextState)
    }
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  return <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>
}

export default DragWrapper
