import Classnames from 'classnames'
import { useState } from 'react'
import { useAtom } from 'jotai'
import {
  manifestStatic,
  pageContentStatic,
  manifestDesign,
  pageContentDesign,
  manifestBlocks,
  pageContentBlocks,
  isBuilder,
  headerSize,
} from '../_services/store'

import { returnValue } from '../_services/utils/storeHelperFunctions'

// shared components
import StaticContentWrapper from '../_services/components/shared/StaticContentWrapper'
import Video from '../_services/components/shared/Video'
import OnepagerFooter from '../_services/components/shared/OnepagerFooter'

import CopyHeader from './components/CopyHeader'
import BlocksHeader from './components/BlocksHeader'

// sharedBlock renderer
import BlockRenderer from '../_services/components/sharedBlocks/BlockRenderer'

// local components
import SignupBlock from './components/SignupBlock'

const Render = ({ id }) => {
  const [builder] = useAtom(isBuilder)

  // manifest settings
  const [opStatic] = useAtom(manifestStatic)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  // custom page settings
  const [pageStatic] = useAtom(pageContentStatic)
  const [pageDesign] = useAtom(pageContentDesign)
  const [pageBlocks] = useAtom(pageContentBlocks)

  const [getHeaderSize, setHeaderSize] = useAtom(headerSize)

  const [fullScreen, setFullScreen] = useState(false)

  return (
    <div
      className=" md:flex md:overflow-hidden opr-sidebar-parent"
      style={{
        height: builder ? 'calc(100vh - 64px)' : 'calc(100vh - ' + getHeaderSize + 'px)',

        // height: 'calc(100vh - ' + getHeaderSize + 'px)',
      }}
    >
      {/* copy side */}
      <div
        className={Classnames(
          'md:h-full md:w-1/3 opr-sidebar  text-white shadow-xl border-r border-black border-opacity-25 relative z-20 pb-24',
          {
            '': !fullScreen,
            hidden: fullScreen,
            // 'md:w-1/2': returnValue('widthstyle', opDesign, pageDesign) == '50%',
            // 'md:w-1/3': returnValue('widthstyle', opDesign, pageDesign) == '33%',
            // 'md:w-1/4': returnValue('widthstyle', opDesign, pageDesign) == '25%',
          }
        )}
        style={{
          backgroundColor: returnValue('bgcolor', opDesign, pageDesign),
          color: returnValue('maintextcolor', opDesign, pageDesign),
          boxShadow: '10px 0 11px 4px rgba(0,0,0,0.08), 5px 0 7px 4px rgba(0,0,0,0.1)',
        }}
      >
        {/* background image */}
        {returnValue('bgimgurl', opDesign, pageDesign) && (
          <div
            className="absolute top-0 left-0 z-0 h-full w-full  bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${returnValue('bgimgurl', opDesign, pageDesign)})`,
              opacity: returnValue('bgimgopacity', opDesign, pageDesign),
            }}
          ></div>
        )}
        <CopyHeader />

        {/* <div
          style={{
            width: '100%',
            height: '100%',
            // height: builder ? 'calc(100vh - 8rem)' : 'calc(100vh - 4rem)',
            // height: 'calc(100vh - ' + getHeaderSize + 'px)',
          }}
          className="relative z-20"


        > */}
        <div className="p-12 pb-20 relative z-20">
          {/* video */}
          {returnValue('videourl', opStatic, pageStatic) && (
            <Video url={returnValue('videourl', opStatic, pageStatic)} classNames="mb-8" />
          )}

          {/* img */}
          {returnValue('imgurl', opStatic, pageStatic) && (
            <img
              src={returnValue('imgurl', opStatic, pageStatic)}
              alt=""
              className="opr-header-image rounded shadow-lg mb-8"
            />
          )}

          {/* headline / subheadline */}
          <div className="opr-sidebar-header-text">
            <h1
              className={Classnames('font-bold text-6xl', {
                appTitleItem: returnValue('herofont', opDesign, pageDesign) == 'sans',
              })}
              style={{
                color: returnValue('headlinetextcolor', opDesign, pageDesign),
              }}
            >
              <StaticContentWrapper contentKey="headline">
                {returnValue('headline', opStatic, pageStatic)}
              </StaticContentWrapper>
            </h1>
            <p className="mt-6 text-xl font-medium">
              <StaticContentWrapper contentKey="subheadline">
                {returnValue('subheadline', opStatic, pageStatic)}
              </StaticContentWrapper>
            </p>
          </div>

          {/* divider */}
          <div
            className="opr-sidebar-divider w-1/4 h-1 rounded opacity-20 my-8"
            style={{
              backgroundColor: returnValue('copydivider', opDesign, pageDesign),
            }}
          ></div>

          {/* long form copy */}
          {/* <StaticContentWrapper contentKey="maincopy">
            <div
              className="prose opr-maincopy"
              dangerouslySetInnerHTML={{
                __html: returnValue('maincopy', opStatic, pageStatic),
              }}
              style={{
                color: returnValue('maintextcolor', opDesign, pageDesign),
              }}
            ></div>
          </StaticContentWrapper> */}
        </div>
        {!builder && (
          <div
            className="hidden md:block md:fixed bottom-1 left-2.5 z-40"
            style={{
              color: returnValue('maintextcolor', opDesign, pageDesign),
            }}
          >
            {/* <OnepagerFooter /> */}
          </div>
        )}
        {/* </div> */}
      </div>

      {/* side scroller content blocks */}
      <div
        className={Classnames('opr-main-area md:h-full md:w-2/3 overflow-y-auto shadow-inner', {
          // 'md:w-1/2': !fullScreen,
          // 'md:w-full': fullScreen,
          // 'md:w-1/2': returnValue('widthstyle', opDesign, pageDesign) == '50%',
          // 'md:w-2/3': returnValue('widthstyle', opDesign, pageDesign) == '33%',
          // 'md:w-3/4': returnValue('widthstyle', opDesign, pageDesign) == '25%',
        })}
        style={{
          backgroundColor: returnValue('blockbgcolor', opDesign, pageDesign),
          color: returnValue('blocktextcolor', opDesign, pageDesign),
        }}
      >
        <BlocksHeader />

        <div className="p-4 md:p-12 space-y-4">
          <BlockRenderer />
        </div>
      </div>
    </div>
  )
}

export default Render
