import { useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import produce from 'immer'

// store
import { isBuilder, userContents, todosFocusId } from '../../../store'

// component
import TextareaAutosize from 'react-textarea-autosize'

const TodoInput = ({
  value,
  placeholder,
  blockId,
  contentKey,
  index,
  className,
  newTodoText,
  addNewOnEnter,
  onEnter,
  onFocus,
  onBlur,
  autoFocus,
}) => {
  const [builder] = useAtom(isBuilder)
  // custom page settings
  const [getUserContents, setUserContents] = useAtom(userContents)
  // todo focus
  const [getTodoFocusId, setTodoFocusId] = useAtom(todosFocusId)

  const refInput = useRef('')

  useEffect(() => {
    if (autoFocus) {
      refInput.current.focus()
      refInput.current.selectionStart = refInput.current.selectionEnd = refInput.current.value.length
    }
  }, [autoFocus])

  function addNewTodo() {
    const newTodoId = 'todo-' + guidGenerator()

    const nextState = produce(getUserContents, draft => {
      draft[blockId][contentKey].splice(index + 1, 0, {
        id: newTodoId,
        text: newTodoText ? newTodoText : '',
        completed: false,
      })
    })
    setUserContents(nextState)

    setTodoFocusId(newTodoId)
  }

  function deleteTask() {
    const getPreviousInputId = getUserContents[blockId][contentKey][index - 1]

    const nextState = produce(getUserContents, draft => {
      draft[blockId][contentKey].splice(index, 1)
    })
    setUserContents(nextState)

    setTimeout(function () {
      setTodoFocusId(getPreviousInputId?.id)
    }, 200)
  }

  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
  }

  return (
    <TextareaAutosize
      ref={refInput}
      // autoFocus={autoFocus}
      style={{ overflow: 'hidden' }}
      className={'block resize-none w-full bg-transparent outline-none overflow-hidden' + className}
      value={value}
      placeholder={placeholder}
      onChange={e => {
        // reset autofocus
        setTodoFocusId('')

        const nextState = produce(getUserContents, draft => {
          draft[blockId][contentKey][index].text = e.target.value
        })
        setUserContents(nextState)
      }}
      onKeyDown={e => {
        // on enter
        if (e.key === 'Enter') {
          if (addNewOnEnter) {
            addNewTodo()
            e.preventDefault()
          } else {
            onEnter()
          }
        }

        // backspace
        if (e.key == 'Backspace') {
          if (value) {
          } else {
            deleteTask()
          }
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default TodoInput
