import { useAtom } from 'jotai'
import { cardsShowModal } from '../../../store'

const CardModal = ({
  className,
  width,
  children,
  deleteButton,
  closeButton,
  overlay,
}) => {
  // card
  const [showCardModal] = useAtom(cardsShowModal)

  if (showCardModal) {
    return (
      <div className="fixed top-0 left-0 w-full h-full z-40 flex items-center justify-center">
        {overlay}
        <div className={className} style={{ width: width ? width : '500px' }}>
          {children}

          {/* footer area */}
          <div className="mt-4 flex items-center justify-end space-x-3">
            {deleteButton}
            {closeButton}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default CardModal
