import { useAtom } from 'jotai'
import { Draggable } from 'react-beautiful-dnd'

// store
import { cardsShowModal, cardsEditId, cardsBlockEditId } from '../../../store'

const CardItem = ({
  blockId,
  itemId,
  index,
  contentKey,
  className,
  children,
  handle,
  remove,
  style,
}) => {
  const [getCardEditId, setCardEditId] = useAtom(cardsEditId)
  const [getCardBlockEditId, setCardBlockEditId] = useAtom(cardsBlockEditId)
  const [showCardModal, toggleCardModal] = useAtom(cardsShowModal)

  function tiggerModal() {
    setCardEditId(itemId)
    setCardBlockEditId(blockId)
    toggleCardModal(true)
  }

  return (
    <Draggable key={itemId} draggableId={itemId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={tiggerModal}
        >
          <div className={'relative group ' + className} style={style}>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default CardItem
