import Classnames from 'classnames'

import { useAtom } from 'jotai'

import { returnValue, returnBlockValue } from '../../utils/storeHelperFunctions'

// store
import { isBuilder, manifestDesign, pageContentDesign, manifestBlocks } from '../../store'

import { RiExternalLinkLine } from 'react-icons/ri'

const HeaderBlock = ({ id, block, design, content, noStyle }) => {
  const [builder] = useAtom(isBuilder)
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  const [pageDesign] = useAtom(pageContentDesign)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50  hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div
        className={Classnames('', {
          'md:flex items-center justify-between': !noStyle,
        })}
      >
        <div className="">
          <div className="text-xl font-bold">
            {returnBlockValue('title', 'content', opBlocks[block], content)}
          </div>

          <div className="text-lg mt-1 opacity-70">
            {returnBlockValue('desc', 'content', opBlocks[block], content)}
          </div>
        </div>

        <div
          className={Classnames('', {
            'mt-3 md:mt-0': !noStyle,
            'mt-6': noStyle,
          })}
        >
          <button
            className="py-2 px-5 font-bold text-lg rounded-md flex items-center space-x-3"
            onClick={() => {
              if (!builder) {
                if (returnBlockValue('buttonurl', 'content', opBlocks[block], content)) {
                  window
                    .open(returnBlockValue('buttonurl', 'content', opBlocks[block], content), '_blank')
                    .focus()
                }
              }
            }}
            style={{
              backgroundColor: returnBlockValue('buttoncolor', 'design', opBlocks[block], design),
              color: returnBlockValue('buttontextcolor', 'design', opBlocks[block], design),
            }}
          >
            <div className="">{returnBlockValue('buttontext', 'content', opBlocks[block], content)}</div>

            <div className="">
              <RiExternalLinkLine />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default HeaderBlock
