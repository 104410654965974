import Classnames from 'classnames'

import { useAtom } from 'jotai'

// helper functions
import { returnValue, returnBlockValue } from '../../utils/storeHelperFunctions'

import Linker from '../headless/Link'

// store
import { manifestDesign, pageContentDesign, manifestBlocks } from '../../store'

const LinkBlock = ({ id, block, design, content, noStyle }) => {
  const [opDesign] = useAtom(manifestDesign)
  const [opBlocks] = useAtom(manifestBlocks)
  const [pageDesign] = useAtom(pageContentDesign)

  return (
    <div
      className={Classnames('transition-all', {
        'rounded px-6 pb-6 pt-5 md:px-10 md:pb-10 md:pt-8 transition-all duration-150 ease-out shadow-md ring-1 ring-gray-300 ring-offset-2 border border-gray-200 ring-offset-gray-50  hover:shadow-xl focus-within:shadow-xl focus-within:ring-2 focus-within:ring-gray-600 focus-within:ring-opacity-20 focus:border-transparent':
          !noStyle,
      })}
      style={{
        backgroundColor: noStyle ? '' : returnValue('cardbgcolor', opDesign, pageDesign),
        color: returnValue('cardtextcolor', opDesign, pageDesign),
        // borderColor: returnValue('cardbordercolor', opDesign, pageDesign),
        // borderWidth: returnValue('cardbordersize', opDesign, pageDesign),
      }}
    >
      <div className="text-xl font-bold">
        {returnBlockValue('title', 'content', opBlocks[block], content)}
      </div>

      <div className="text-lg mt-1 opacity-70">
        {returnBlockValue('desc', 'content', opBlocks[block], content)}
      </div>

      <div className="mt-4 space-y-3">
        {returnBlockValue('links', 'content', opBlocks[block], content).map((data, index) => (
          <Linker key={index} data={data.url} className="">
            <div
              className="cursor-pointer transition-all bg-gray-50 hover:bg-blue-100 duration-150 ease-out  ring-1 ring-gray-300 hover:ring-blue-500 hover:ring-offset-blue-100 ring-offset-2 ring-offset-gray-50  hover:shadow-xl rounded p-3 shadow mb-1.5 flex flex-col justify-center md:justify-start md:flex md:flex-row items-center"
              style={{
                // backgroundColor: returnValue('theme_cardbg', opDesign, pageDesign),
                color: returnValue('theme_cardtext', opDesign, pageDesign),
                // borderColor: returnValue('theme_cardborder', opDesign, pageDesign),
              }}
            >
              {data.url.meta.img && (
                <div className="md:mr-4 flex-shrink-0">
                  <img
                    src={data.url.meta.img}
                    alt=""
                    className="w-full mb-3 md:mb-0 md:h-22 md:w-36  rounded shadow"
                  />
                </div>
              )}
              <div className="w-full md:pr-6 flex-grow">
                <div className="font-bold text-lg">{data.url.meta.title}</div>
                <div className="font-medium line-clamp-1 opacity-50 text-sm">{data.url.meta.desc}</div>
                <div className="font-medium  text-sm text-blue-500 mt-1">{data.url.url}</div>
              </div>
            </div>
          </Linker>
        ))}
      </div>
    </div>
  )
}

export default LinkBlock
