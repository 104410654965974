export default {
  grid: {
    settings: {
      title: 'Grid',
      desc: 'Grid Canvas Block',
      type: 'grid',
      meta_title_key: 'grid',
    },
    content: {
      blocks: {
        value: [],
      },
    },
    design: {},
  },
  // GRID
  // gridClassic: {
  //   settings: {
  //     title: 'Grid',
  //     desc: 'Grid Canvas Block',
  //     type: 'grid',
  //     meta_title_key: 'grid',
  //   },

  //   content: {
  //     blocks: {
  //       value: [
  //         {
  //           id: 'block-ba8ebf07-2404-7947-4026-656324357a35',
  //           block: 'todosblock',
  //           content: {},
  //           design: {},
  //           boxLayoutId: 'box-ffe3f862-fa22-1180-3f18-b7e564b6c7f0',
  //         },
  //         {
  //           id: 'block-e084e09a-6973-2df5-2c50-d00703bd7d0e',
  //           block: 'todosblock',
  //           content: {},
  //           design: {},
  //           boxLayoutId: 'box-c6283138-361a-c24c-be11-655dfd854b6a',
  //         },
  //         {
  //           id: 'block-501c0f00-b2d9-b6bf-7b1f-7f9eff92421c',
  //           block: 'linkblock',
  //           content: {},
  //           design: {},
  //           boxLayoutId: 'box-c0bd0790-2ed7-a3dd-e1dc-fba84c339506',
  //         },
  //       ],
  //     },
  //     gridLayout: {
  //       label: 'Layout Text',
  //       desc: '',
  //       // control: 'input',
  //       value: [
  //         {
  //           w: 3,
  //           h: 2,
  //           x: 0,
  //           y: 0,
  //           i: 'box-ffe3f862-fa22-1180-3f18-b7e564b6c7f0',
  //           minW: 1,
  //           moved: false,
  //           static: false,
  //         },
  //         {
  //           w: 3,
  //           h: 2,
  //           x: 3,
  //           y: 0,
  //           i: 'box-c6283138-361a-c24c-be11-655dfd854b6a',
  //           minW: 1,
  //           moved: false,
  //           static: false,
  //         },
  //         {
  //           w: 6,
  //           h: 4,
  //           x: 0,
  //           y: 2,
  //           i: 'box-c0bd0790-2ed7-a3dd-e1dc-fba84c339506',
  //           minW: 1,
  //           moved: false,
  //           static: false,
  //         },
  //       ],
  //     },
  //     gridLayoutCSS: {
  //       label: 'gridLayoutCSS',
  //       desc: '',
  //       // control: 'input',
  //       value: [
  //         {
  //           i: 'box-ffe3f862-fa22-1180-3f18-b7e564b6c7f0',
  //           'grid-row-start': 1,
  //           'grid-column-start': 1,
  //           'grid-row-end': 2,
  //           'grid-column-end': 3,
  //         },
  //         {
  //           i: 'box-c6283138-361a-c24c-be11-655dfd854b6a',
  //           'grid-row-start': 1,
  //           'grid-column-start': 4,
  //           'grid-row-end': 2,
  //           'grid-column-end': 3,
  //         },
  //         {
  //           i: 'box-c0bd0790-2ed7-a3dd-e1dc-fba84c339506',
  //           'grid-row-start': 3,
  //           'grid-column-start': 1,
  //           'grid-row-end': 4,
  //           'grid-column-end': 6,
  //         },
  //       ],
  //     },
  //   },
  //   design: {},
  // },

  // HEADER
  header: {
    settings: {
      title: 'Header',
      desc: 'Headline content block',
      type: 'content',
      meta_title_key: 'headline',
    },
    content: {
      headline: {
        label: 'Header Text',
        desc: '',
        control: 'input',
        value: '...',
      },
      subheadline: {
        label: 'Sub Headline',
        desc: '',
        control: 'input',
        value: '',
      },
    },
    design: {
      // headlinesize: {
      //   label: 'Headline Size',
      //   desc: '',
      //   control: 'select',
      //   value: 'default',
      //   options: [
      //     { label: 'Default', value: 'lg' },
      //     { label: 'Extra Large', value: 'xl' },
      //     { label: 'Very Large', value: '2xl' },
      //   ],
      // },
      bordertoggle: {
        label: 'Border Toggle',
        desc: '',
        control: 'select',
        value: 'noborder',
        options: [
          { label: 'Hide Border', value: 'noborder' },
          { label: 'Show Border', value: 'showborder' },
        ],
      },
      bordercolor: {
        label: 'Border Color',
        desc: 'optional',
        control: 'colorpicker',
        value: '#000',
      },
    },
  },

  // INPUT BLOCK
  inputblock: {
    settings: {
      title: 'Text Input',
      desc: 'Simple text input block',
      type: 'editable',
      meta_title_key: 'title',
    },
    usercontent: {
      value: '',
    },
    content: {
      title: {
        label: 'Title',
        desc: '',
        control: 'input',
        value: 'Title...',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      placeholder: {
        label: 'Placehoolder',
        desc: '',
        control: 'input',
        value: '...',
      },
      context: {
        label: 'Context Helper Text',
        desc: 'optional',
        control: 'input',
        value: '',
      },
    },
    design: {},
  },

  // CARDS
  cardblock: {
    settings: {
      title: 'Cards',
      desc: 'Cards editable list',
      type: 'editable',
      meta_title_key: 'title',
    },
    usercontent: {
      cards: [],
    },
    content: {
      title: {
        label: 'Headline Text',
        desc: '',
        control: 'input',
        value: 'Title',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      addcardbutton: {
        label: 'Add Card Button',
        desc: '',
        control: 'input',
        value: 'Add Card',
      },
      newcardtext: {
        label: 'New Card Default Text',
        desc: '',
        control: 'input',
        value: '...',
      },
    },
    design: {
      addbuttoncolor: {
        label: 'Add Card Button Color',
        desc: '',
        control: 'colorpicker',
        value: '#e4e7eb',
      },
      addbuttontextcolor: {
        label: 'Add Card Button Text Color',
        desc: '',
        control: 'colorpicker',
        value: '#000',
      },
      addbuttonsize: {
        label: 'Add Card Button Size',
        desc: '',
        control: 'select',
        value: 'default',
        options: [
          { label: 'Default', value: 'default' },
          { label: 'Large', value: 'lg' },
          { label: 'Small', value: 'sm' },
        ],
      },
    },
  },

  // TODOS
  todosblock: {
    settings: {
      title: 'Todos',
      desc: 'Tasks / todos list',
      type: 'editable',
      meta_title_key: 'title',
    },
    usercontent: {
      todos: [],
    },
    usercontent_schema: {
      todos: 'todos',
    },
    content: {
      title: {
        label: 'Title',
        desc: '',
        control: 'input',
        value: 'Title...',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      addtodobutton: {
        label: 'Add Todo Button',
        desc: '',
        control: 'input',
        value: 'Add Todo',
      },
    },
    design: {},
  },

  // CTA Button Block
  ctablock: {
    settings: {
      title: 'Call to action button',
      desc: 'Call attention to a link',
      type: 'content',
      meta_title_key: 'title',
    },
    content: {
      title: {
        label: 'Title',
        desc: '',
        control: 'input',
        value: 'Title...',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      buttontext: {
        label: 'Button Text',
        desc: '',
        control: 'input',
        value: 'Button',
      },
      buttonurl: {
        label: 'URL',
        desc: '',
        control: 'input',
        value: '',
      },
    },
    design: {
      buttoncolor: {
        label: 'Button Background Color',
        desc: '',
        control: 'colorpicker',
        value: '#000',
      },
      buttontextcolor: {
        label: 'Button Text Color',
        desc: '',
        control: 'colorpicker',
        value: '#FFF',
      },
    },
  },

  // Link Block
  linkblock: {
    settings: {
      title: 'Link Block',
      desc: 'list a bunch of links',
      type: 'content',
      meta_title_key: 'title',
    },
    content: {
      title: {
        label: 'Title',
        desc: '',
        control: 'input',
        value: 'Title...',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      links: {
        label: 'Links',
        desc: '',
        control: 'repeatable',
        value: [],
        btnText: 'Add Link',
        meta_title_key: 'linkobj',
        schema: {
          url: {
            label: 'url',
            desc: '',
            control: 'linkinput',
            value: {
              url: '',
              meta: {},
            },
          },
        },
      },
    },
    design: {},
  },

  // MULTI CHOICE BLOCK
  multichoiceblock: {
    settings: {
      title: 'Multi Choice',
      desc: 'Multi choice select option',
      type: 'editable',
      meta_title_key: 'title',
    },
    usercontent: {
      selected: '',
    },
    content: {
      title: {
        label: 'Title',
        desc: '',
        control: 'input',
        value: 'Title...',
      },
      desc: {
        label: 'Sub title',
        desc: 'optional',
        control: 'input',
        value: '',
      },
      radio_options: {
        label: 'Multi Choice Options',
        desc: '',
        control: 'repeatable',
        value: [],
        btnText: 'Add Option',
        meta_title_key: 'option',
        schema: {
          option: {
            label: 'Option Value',
            desc: 'not visible',
            control: 'input',
            value: '',
          },
          headline: {
            label: 'Headline',
            desc: '',
            control: 'input',
            value: 'Option Headline',
          },
          subheadline: {
            label: 'Sub Headline',
            desc: '',
            control: 'input',
            value: '',
          },
        },
      },
    },
    design: {
      gridlayout: {
        label: 'Grid Layout',
        desc: '',
        control: 'select',
        value: 'col-1',
        options: [
          { label: '1 Column', value: 'col-1' },
          { label: '2 Column', value: 'col-2' },
          { label: '3 Column', value: 'col-3' },
          { label: '4 Column', value: 'col-4' },
        ],
      },
    },
  },
}
