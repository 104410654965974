import { Droppable } from 'react-beautiful-dnd'

const CardsWrapper = ({ contentKey, blockId, className, children }) => {
  return (
    <Droppable droppableId={blockId} type={contentKey}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={className}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default CardsWrapper
