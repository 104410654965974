import Blocks from './Blocks'
import LightTheme from './themes/Light'

export default {
  id: '123',
  type: 'editable',

  static: {
    headline: {
      label: 'Main Headline',
      desc: '',
      control: 'input',
      value: 'Main Headline ',
    },
    subheadline: {
      label: 'Sub Headline',
      desc: '',
      control: 'textarea',
      value: 'Sub Headline text...',
    },
    covertitle: {
      label: 'Cover settings',
      desc: '',
      control: 'title',
      value: 'blue',
    },
    videourl: {
      label: 'Video',
      desc: 'optional video (youtube / vimeo / mp4 )',
      control: 'input',
      value: '',
    },
    imgurl: {
      label: 'Image',
      desc: 'optional cover image',
      control: 'upload',
      value: '',
    },
    blockheadline: {
      label: 'Content Headline',
      desc: 'small headline',
      control: 'input',
      value: 'Edit this content...',
    },
    savebutton: {
      label: 'Save Button Text',
      desc: 'copy on the save button',
      control: 'input',
      value: 'Save Your Changes',
    },
  },

  design: {
    bgcolor: {
      label: 'Hero Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#4c5664',
    },

    bgimgurl: {
      label: 'Hero Background Image',
      desc: 'optional',
      control: 'upload',
      value: '',
    },
    bgimgopacity: {
      label: 'BG Image Opacity',
      desc: 'optional',
      control: 'slider',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.1,
    },
    maintextcolor: {
      label: 'Hero Text Color',
      desc: 'text color for the left side',
      control: 'colorpicker',
      value: '#FFF',
    },
    herofont: {
      label: 'Hero Font',
      desc: '',
      control: 'select',
      value: 'default',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Sans', value: 'sans' },
      ],
    },
    headlinetextcolor: {
      label: 'Hero Headline Text Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },
    pagebg: {
      label: 'Main Page Background Color',
      desc: '',
      control: 'colorpicker',
      value: '#FFF',
    },

    savebutton: {
      label: 'Save Button Color',
      desc: '',
      control: 'colorpicker',
      value: '#000',
    },

    ...LightTheme,
  },

  // blocks: {
  //   header: Blocks.header,
  // },

  blocks: {
    ...Blocks,
  },
}
