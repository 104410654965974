// dev testing
// import BookReviewManifest from '@/utils/op-types/BookReview'
// import LinkTreeManifest from '@/utils/op-types/LinkTree'
// import PHChatManifest from '@/utils/op-types/PHChat'

import GridManifest from './Grid'

// // production types
import SideScrollerManifest from './SideScroller'
import CenterScrollerManifest from './CenterScroller'
import CardScrollerManifest from './CardScroller'
// import SimpleGridScrollerManifest from '@/utils/op-types/SimpleGridScroller'
// import StickyScrollerManifest from '@/utils/op-types/StickyScroller'

// /* BLOCK_INJECT_IMPORT */
// import AskCampaignManifest from '@/utils/op-types/AskCampaign'
// import StickyPageManifest from '@/utils/op-types/StickyPage'
// import SimpleChecklistManifest from '@/utils/op-types/SimpleChecklist'
// import SimpleKanbanManifest from '@/utils/op-types/SimpleKanban'
// import HubPageManifest from '@/utils/op-types/HubPage'
// import ThreeColumnResourcesManifest from '@/utils/op-types/ThreeColumnResources'
// import SimpleResourceListManifest from '@/utils/op-types/SimpleResourceList'

export default {
  grid: GridManifest,
  // dev testing
  // bookreview: BookReviewManifest,
  // linktree: LinkTreeManifest,
  // phchat: PHChatManifest,
  // production
  sidescroller: SideScrollerManifest,
  centerscroller: CenterScrollerManifest,
  cardscroller: CardScrollerManifest,
  // simplegridscroller: SimpleGridScrollerManifest,
  // stickyscroller: StickyScrollerManifest,
  // /* BLOCK_INJECT_EXPORT */
  // askcampaign: AskCampaignManifest,
  // stickypage: StickyPageManifest,
  // simplechecklist: SimpleChecklistManifest,
  // simplekanban: SimpleKanbanManifest,
  // hubpage: HubPageManifest,
  // threecolumnresources: ThreeColumnResourcesManifest,
  // simpleresourcelist: SimpleResourceListManifest,
}
