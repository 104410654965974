import { ErrorBoundary } from 'react-error-boundary'

import JotaiStoreWrapper from '../JotaiStoreWrapper'

import CatchError from './CatchError'

import SEO from './SEO'
import Tracking from './Tracking'

import AutoSave from './AutoSave'

import SignUpModal from './SignUpModal'
import CollectionHeader from './CollectionHeader'
import ShareModal from './ShareModal'
import ShareHeader from './ShareHeader'

import PublicHelloBar from './PublicHelloBar'

function ErrorFallback({ error, resetErrorBoundary }) {
  return <CatchError error={error} resetErrorBoundary={resetErrorBoundary} />
}

const BlockWrapper = ({
  id,
  uid,
  collectionId,
  manifest,
  is_builder,
  description,
  title,
  custom_share_image,
  content,
  subdomain,
  custom_css,
  children,
  collectionHeader,
  collectionHeaderContent,
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {/* seo meta data */}
      <SEO id={id} subdomain={subdomain} share_image={custom_share_image} custom_css={custom_css} />

      {/* tracking */}
      <Tracking id={id} uid={uid} collectionId={collectionId} />

      {/* share header if share header */}
      <ShareHeader />

      {/* collection header */}
      {/* <CollectionHeader show={collectionHeader} content={collectionHeaderContent} /> */}

      {/* promotional bar */}
      <PublicHelloBar id={id} />

      {/* render onepager */}
      {children}

      {/* auto save */}
      <AutoSave id={id} />

      {/* sign up modal */}
      <SignUpModal id={id} />

      {/* share modal */}
      <ShareModal id={id} />
    </ErrorBoundary>
  )
}

export default BlockWrapper
