const OnepagerFooter = () => {
  return (
    <div className="py-12 text-center font-bold">
      <div className="inline-block bg-white text-black bg-opacity-80 transition rounded shadow-lg py-1.5 px-3 group hover:bg-brand-yellow hover:text-gray-800 cursor-pointer">
        <a
          href="https://onepager.io?utm-campaign=footer"
          target="_blank"
          className="text-xs opacity-80 group-hover:opacity-100"
        >
          Powered by Onepager.io
        </a>
      </div>
    </div>
  )
}

export default OnepagerFooter
